import React, { useState, useEffect,useRef } from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Modal from 'react-bootstrap/Modal';
import { web,user } from '../../helpers/api';
import SimpleReactValidator from 'simple-react-validator';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import { message, entitiesLabels, ENDPOINT } from '../../helpers/Constants';
import { isAdmin, isOperatore, IsMedico } from '../../utils';
function RegistrazioneOperatore() {
  const validator = useRef(new SimpleReactValidator())
  const [forceUpdate, setForceUpdate] = useState(false);
  const [isOpenModalResetPassword, setIsOpenModalResetPassword] = useState(false);
  const [formData, setFormData] = useState({
    nome: '',
    cognome: '',
    email: '',
    telefono: '',
    idOperatore: 0,
  });
  useEffect(() => {

    fetchProfile();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = () => {
        if (validator.current.allValid()) {
            web.post(ENDPOINT.SALVAOPERATORE, formData)
                .then(async (response) => {
                    if (response.status === 200 && response.data.statoEsito === 0) {
                        NotificationManager.success(message.PAZIENTE + message.SuccessUpdate, entitiesLabels.SUCCESS, 3000);
                        fetchProfile();
                    } else {
                        NotificationManager.error(response.data.descrizioneEsito, entitiesLabels.ERROR, 3000);
                    }
                }).catch((error) => {
                    NotificationManager.error(message.ErrorServer, entitiesLabels.ERROR, 3000);

                })
                .finally(() => {
                    fetchProfile();
                });
        } else {
            validator.current.showMessages();
            setForceUpdate(!forceUpdate);
        }
  };
  const fetchProfile = async () => {
    let id = window.location.pathname.split('/').pop();
    id = parseInt(id);
    if (id) {
      await web.post(ENDPOINT.DETTAGLIOOPERATORE, id)
        .then((response) => {
          if (response.status === 200 && response.data.dati != null) {
            setFormData(response.data.dati);
          }
        }).catch((error) => {

        });
    }
  };
  const resetPassword = () => {
    user.post(ENDPOINT.REQUESTNEWPASSWORD, formData.email)
        .then(async (response) => {
            if (response.status === 200 && response.data.statoEsito === 0) {
                NotificationManager.success(message.PAZIENTE + message.SuccessUpdate, entitiesLabels.SUCCESS, 3000);
            } else {
                NotificationManager.error(response.data.descrizioneEsito, entitiesLabels.ERROR, 3000);
            }
        }).catch((error) => {

        }).finally(() => {
            setIsOpenModalResetPassword(false)
        });
    setIsOpenModalResetPassword(false)
};
function handleCloseModal() {
    setIsOpenModalResetPassword(false)
}
  const validations = {
    nome: validator.current.message(
        'nome',
        formData.nome,
        'required'
    ),
    cognome: validator.current.message(
        'cognome',
        formData.cognome,
        'required'
    ),
    email: validator.current.message(
        'email',
        formData.email,
        'required|email'
    ),
    telefono: validator.current.message(
        'telefono',
        formData.telefono,
        'required|number'
    ),

}
  return (
    <div className="container" style={{ maxWidth: '600px', margin: '0 auto' }}>
      {parseInt(window.location.pathname.split('/').pop()) ?
                <h2 className="text-center">Modifica Operatore</h2>
                : <h2 className="text-center">Registrazione Operatore</h2>
            }
      <Form onSubmit={handleSubmit}>
        <Row className="mb-3">
          <Col>
            <Form.Group controlId="formNome">
              <Form.Label>Nome:</Form.Label>
              <Form.Control
                type="text"
                name="nome"
                isInvalid={validations.nome != null}
                value={formData.nome}
                onChange={handleInputChange}
                
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId="formCognome">
              <Form.Label>Cognome:</Form.Label>
              <Form.Control
                type="text"
                name="cognome"
                isInvalid={validations.cognome != null}
                value={formData.cognome}
                onChange={handleInputChange}
                
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Group className="mb-3" controlId="">
              <Form.Label>Telefono :</Form.Label>
              <div className="position-relative">
                <Form.Control
                  isInvalid={validations.telefono != null}
                  type={'text'}
                  name="telefono"
                  value={formData.telefono ? formData.telefono : ''}
                  onChange={handleInputChange}
                />
              </div>
            </Form.Group>
          </Col>
        </Row>
        <Form.Group className="mb-3" controlId="formEmail">
          <Form.Label>Email:</Form.Label>
          <Form.Control
            type="email"
            name="email"
            isInvalid={validations.email != null}
            value={formData.email}
            onChange={handleInputChange}
            
          />
        </Form.Group>

        <Row>
                <Col>
                    {isAdmin()  ?
                        <Button onClick={() => handleSubmit()} variant="primary" className="w-100">{parseInt(window.location.pathname.split('/').pop()) ?
                            "Modifica" :
                            "Registra"
                        }</Button> : <></>
                    }
                </Col>
                <Col>
                    {isOperatore()  ? <></> :
                       parseInt(window.location.pathname.split('/').pop()) ? <Button onClick={() => setIsOpenModalResetPassword(!isOpenModalResetPassword)} variant="primary" className="w-100">Reset Password</Button> : <></>
                    }
                </Col>
            </Row>
            <NotificationContainer />
            {isOpenModalResetPassword ?
                <>
                    <Modal show={true} onHide={() => handleCloseModal()} className='custom-m-modal '>
                        <Modal.Header closeButton>
                            <Modal.Title>Reset Password</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Row className="mb-6">
                                <h6>Stai per inviare una nuova password provvisoria all'email {formData.email}, sei sicuro di volter proseguire? </h6>
                            </Row>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={() => handleCloseModal()}  >
                                Close
                            </Button>
                            <Button className="p-t-10" variant="primary" id="button-addon1" onClick={() => resetPassword()} >Invia nuova password</Button>
                        </Modal.Footer>
                    </Modal>
                </>
                : <></>
            }
      </Form>
    </div>
  );
}

export default RegistrazioneOperatore;