import { Form, Button } from 'react-bootstrap';
import { useState,useEffect } from 'react';
import Pagination from '../CustomComponent/Pagination';
import RowCustom from '../CustomComponent/CustomRow';
import Modal from 'react-bootstrap/Modal';
import { PATH } from '../../helpers/Constants'
import { useNavigate } from 'react-router-dom';
import { web } from '../../helpers/api';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import { message, entitiesLabels, ENDPOINT } from '../../helpers/Constants';
function ListaCentriMedici() {
  const navigate = useNavigate();
  const [elements, setCentrimedici] = useState([
  ]);
  // const [loading,] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [elementsPerPage] = useState(10);
  const [search, setSearch] = useState('');
  const [filteredElements, setFilteredElements] = useState([]);
  const [isFilter, setIsFilter] = useState(false);
  const [showModal, setShowModale] = useState(false);
  const [dropOffCentroMedico, setDropOffCentroMedico] = useState({
    idCentroMedico: 0,
    isActive: 0,
  });

  // Get current patient
  const indexOfLastElement = currentPage * elementsPerPage;
  const indexOfFirstElement = indexOfLastElement - elementsPerPage;
  let currentElements = isFilter ? filteredElements?.slice(indexOfFirstElement, indexOfLastElement) : elements?.slice(indexOfFirstElement, indexOfLastElement);

  useEffect(() => { 
   
    fetchCentriMedici();
}, []);
const fetchCentriMedici= async () => {
  await web.getAll(ENDPOINT.ELENCOCENTRIMEDICI)
      .then((response) => {
          if (response.status === 200) {
            setCentrimedici(response.data.dati);
          }
      }).catch((error) => {

      });
};
  // Change page
  const paginate = pageNumber => setCurrentPage(pageNumber);
  const handleClose = () => setShowModale(false);
  const showModalActivePatient = (idCentro) => {
    var element = elements.find((x) => x.id === idCentro);
    const idCentroMedico = 'idCentroMedico';
    const isActive = 'isActive';
    setDropOffCentroMedico({ ...dropOffCentroMedico, [isActive]: element.isActive , [idCentroMedico]: element.id });;
    setShowModale(true)
  }

  const nextPage = (pageNumber) => {
    setCurrentPage(pageNumber + 1)
  }
  const prevPage = (pageNumber) => {
    setCurrentPage(pageNumber - 1)
  }
  const handleChange = (el) => {
    const inputValue = el.target.value.toLowerCase();
    setSearch({
      search:
        inputValue
    });
    if (inputValue) {
      if (search && search?.search.length >= 1) {
        setIsFilter(true);
        let filteredData = elements.filter(x => String(x.nomeCentro).toLowerCase().includes(inputValue)
          || String(x.nomeRegione).toLowerCase().includes(inputValue)
          || String(x.id).toLowerCase().includes(inputValue));
        if (filteredData.length > 0) {
          setFilteredElements(filteredData);

        } else {
          setFilteredElements(filteredData);
        }
        setCurrentPage(1)
      } else {
        setFilteredElements(elements);
      }
    } else {
      setFilteredElements(elements);
    }
  };

  function toAddCentroMedico() {

    navigate(PATH.NUOVOCENTROMEDICO, { replace: true });
  }
  const dopoffcentro = (e) => {
    web.post(ENDPOINT.DISABILITACENTROROMEDICO, e)
      .then(async (response) => {
        if (response.status === 200 && response.data.statoEsito === 0) {
          NotificationManager.success(message.MEDICO + message.SuccessUpdate, entitiesLabels.SUCCESS, 3000);
          fetchCentriMedici();
          setShowModale(false)
        } else {
          NotificationManager.error(response.data.descrizioneEsito, entitiesLabels.ERROR, 3000);
        }
      }).catch((error) => {
        NotificationManager.error(message.ErrorServer, entitiesLabels.ERROR, 3000);

      })
      .finally(() => {
        fetchCentriMedici();
      });
  };
  return (<>
    <div className="container" style={{ maxWidth: '600px', margin: '0 auto' }}>
      <h2 className="text-center">Lista Centri Medici</h2>
    </div>
    <Form.Group className="d-flex mb-3">
      <Form.Control className="me-3" onChange={handleChange} onPaste={handleChange} name="search" alt="medicoDTO" placeholder="Ricerca Centro Medico"/>
      <Button variant="primary" onClick={() => toAddCentroMedico()}>
        Aggiungi Centro Medico
      </Button>
    </Form.Group>
    <>
      <div className="table-wrapper">
        <table className="table custom">
          <thead>
            <tr>
              <th scope="col">Id Centro</th>
              <th scope="col">Regione</th>
              <th scope="col">Nome centro medico</th>
              <th scope="col">Centro Attivo</th>
              <th scope="col">Attiva e disattiva</th>
            </tr>
          </thead>
          <tbody className="rounded-row">
            {
              currentElements?.map((pa) => <RowCustom colums={["id", "nomeRegione", "nomeCentro","isActive", "actions"]} link={'id'} elementUpdate={"actions"} reference={'id'}  update={(el) => showModalActivePatient(el)} controller={'profilocentromedico'} item={pa} />)
            }
          </tbody>
        </table>
      </div>

    </>
    <Pagination
      elementPerPage={elementsPerPage}
      totalElements={isFilter ? filteredElements?.length : elements?.length}
      paginate={paginate}
      currentPage={currentPage}
      prevPage={prevPage}
      nextPage={nextPage}
    />
    <Modal show={showModal} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{dropOffCentroMedico.isActive ? 'Disattiva Centro ' : 'Attiva Centro'}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{dropOffCentroMedico.isActive ? 'Vuoi disattivare il Centro ?' : 'Vuoi riattivare il Centro'}</Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button variant="primary" onClick={() => dopoffcentro(dropOffCentroMedico.idCentroMedico)}>
          {dropOffCentroMedico.isActive ? 'Disattiva' : 'Attiva'}
        </Button>
      </Modal.Footer>
    </Modal>
    <NotificationContainer></NotificationContainer>
  </>

  );
}

export default ListaCentriMedici;