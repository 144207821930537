import ListaWebinar from "../Pages/Tabelle/ListaWebinar";

function textFormat(r) {
  return `/${r}/`;
}
export const entities = {
  USER: 'utente',
  WEB: 'web',
  MEDICO: 'medico',
  PATIENT: 'paziente',
  OPERATOR: 'operatore',
  DASHBOARD:'dashboard'
};

export const API = {
  TODOS: textFormat(entities.TODOS),
  WEB: textFormat(entities.WEB),
  USER: textFormat(entities.USER),
  PATIENT: textFormat(entities.PATIENT),
  OPERATOR: textFormat(entities.OPERATOR),
  MEDICO: textFormat(entities.MEDICO),
  DASHBOARD: textFormat(entities.DASHBOARD),
};

export const entitiesLabels = {
  WARNING: 'Attenzione!',
  USER: 'Utente',
  ERROR: 'Errore!',
  SUCCESS: 'Successo!',
}
export const message = {
  //ERROR
  ErrorLogin: 'Username o password errati ',
  ErrorUnauthorized: 'Utente non autorizzato',
  ErrorServer: 'Errore interno al programma ',
  ErrorRequire: 'Inserire tutti i campi richiesti ',
  ErrorMailNotValid: 'Formato email non valido',
  ErrorNotFound: 'Valore inserito non trovato',
  ///SUCCESS
  SuccessInsert: 'Inserito correttamente ',
  SuccessUpdate: ' Aggiornato correttamente',
  SuccessAssociazione: ' Associazione avvenuta correttamente',
  SuccessSend: 'Inviato Correttamente ',
  ErroSend: 'Non Inviato Correttamente ',
  ErroSendRevocery: 'Email di recupero inviata con successo',
  ///OBJECT
  USER: 'user',
  PAZIENTE: 'Paziente ',
  CENTRO: 'Centro Medico ',
  MEDICO: 'Medico ',
  OPERATORE: 'Operatore ',
  TERAPIA: 'Terapia ',
  CODICE: 'Codice ',
  ESAME: 'Esame ',
  VISITA: 'Visita ',
  RISULTATO: 'Risultato esame ',
}
export const notificationType = {
  PRIMARY: 'primary',
  SUCCESS: 'success',
  DANGER: 'danger',
  WARNING: 'warning',
  INFO: 'info',
}

export const role = {
  MEDICO: 2,
  OPERATORE: 3,
  ADMIN: 4,
  SISTEMA: 5
}

export const PATH = {
  SPLASH: '/',
  LOGIN: '/login',
  RECOVERY: '/recovery',
  PROFILO: '/profilo',
  MODELLO: "/modello",
  DASCHBOARD: '/dashboard',
  DETTAGLIOMODELLO: '/dettagliomodello/:id',
  PROFILOMEDICO: '/profilomedico/:idMedico',
  REGISTRAZIONEMEDICO: '/registrazionemedico',
  PROFILOPAZIENTE: '/profilopaziente/:idPatient',
  NUOVOPAZIENTE: '/nuovopaziente',
  NUOVOOPERATORE: '/nuovooperatore',
  REGISTRAZIONEOPERATORE: '/registrazioneoperatore',
  NUOVOCENTROMEDICO: '/nuovocentromedico',
  PROFILOCENTROMEDICO: '/profilocentromedico/:idCentromedico',
  PROFILOOPERATORE: '/profilooperatore/:idOperatore',
  LISTAOPERATORI: '/listaoperatori',
  LISTAMEDICI: '/listamedici',
  LISTACENTRIMEDICI: '/listacentrimedici',
  LISTAPAZIENTI: '/listapazienti',
  LISTAMODELLI: '/listamodelli',
  LISTAWEBINAR: '/listawebinar'
}

export const ENDPOINT = {
  DETTAGLIOPAZIENTE: 'dettagliopaziente',
  DETTAGLIOCENTROMEDICO : 'dettagliocentromedico',
  DETTAGLIOOPERATORE : 'dettagliooperatore',
  DETTAGLIOMEDICO : 'dettagliomedico',
  ELENCOPAZIENTI: 'elencopazienti',
  ELENCOPAZIENTIASSOCIATI: 'getpazientiassociatiandnotbyidquest',
  ELENCOQUESTIONARI: 'getallquestionari',
  ELENCOPAZIENTIMEDICO: 'elencopazientimedico',
  ELENCOMEDICI:'elencomedici',
  ELENCOWEBINAR:'getallwebinar',
  ELENCOCENTRIMEDICI: 'elencocentrimedici',
  ELENCOCENTRIMEDICIATTIVI: 'elencocentrimediciattivi',
  ELENCOQUESTIONARIPAZIENTE: 'getallquestionariobyidpaziente',
  ELENCOPROVINCIE: 'getprovincie',
  ELENCOCOMUNE: 'getcomuni',
  ELENCOREGIONI: 'getregioni',
  MODIFICAPROFILO:'modificaprofilo',
  SALVAWEBINAR:'salvamodificawebinar',
  SALVAPAZIENTE:'modificaregistrapaziente',
  SALVACENTROMEDICO: 'salvamodificacentrimedici',
  SALVAMEDICO: 'modificaregistramedico',
  SALVAOPERATORE: 'modificaregistraoperatore',
  SALVAVISITA: 'salvavisita',
  SALVAESAME: 'salvaesame',
  SALVATESTESAME:'salvatestesame',
  ELENCOOPERATORI: 'elencooperatori',
  DROPOFFPAZIENTE: 'dropoffpaziente',
  DROPOFFQUESTIONARIO: 'dropoffquestionario',
  DISABILITAMEDICO: 'disabilitamedico',
  ELIMINAWEBINAR: 'deletewebinar/',
  DISABILITACENTROROMEDICO: 'dropoffcentromedico',
  DISABILITAOPERATORE: 'disabilitaoperatore',
  REQUESTNEWPASSWORD: 'requestnewpassword',
  CAMBIOPASSWORD:'cambiopassword',
  GETALLADERENZA:'getalladerenza',
  GETALLVISITE: 'getallvisite',
  GETESAMEVISITA: 'getesamevisita',
  GETTESTESAME:'gettestesame',
  ELIMINATESTESAME: 'deletetestesame/',
  ELIMINAESAME: 'deleteesame/',
  ELIMINAVISITA: 'eliminavisita/',
  ELIMINAALLEGATO: 'deleteallegatoesame/',
  ELIMINADOMANDA: 'deletedomanda/',
  GETALLVISITEESAMI: 'getallvisiteesami',
  GETALLEGATOESAME: 'getallegatoesame',
  UPLOADALLEGATOESAME: 'web/uploadallegatoesame',
  ASSOCIAPAZIENTIQUESTIONARIO: 'associadissociapazientibyquestionario',
  DETTAGLIOQUESTIONARIO:'getdettaglioquestionario',
  SALVADOMANDA:'salvadomandaquestionario',
  SALVARISPOSTADOMANDA: 'salvarispostadomanda',
  GETALLDOMANDEFEEDBACK: 'getalldomandefeedBack',
  GETALLRISPOSTEFEEDBACK: 'getallrispostefeedbackpaziente',
  SALVAFEEDBACK: 'salvamodificarispostafeedback',
  MODIFICATITOLOQUESTIONARIO: 'modificatitoloquestionario',
  GETFILLED: 'getnprofilled',
  GETATTIVIDROPOFF: 'getactivetotaldropoffpatient',
  GETHOSPITALSINVOLVED: 'gehospitalsinvolved',
  GETFEEDBACK: 'getfeedback',
}

