import { Form, Button, Row, Col } from 'react-bootstrap';
import { useState, useEffect, useRef } from 'react';
import Pagination from '../CustomComponent/Pagination';
import RowCustom from '../CustomComponent/CustomRow';
import SimpleReactValidator from 'simple-react-validator';
import Modal from 'react-bootstrap/Modal';
import { web } from '../../helpers/api';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import { message, entitiesLabels, ENDPOINT } from '../../helpers/Constants';
function ListaWebinar() {
    const validator = useRef(new SimpleReactValidator())
    const [elements, setMedici] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [elementsPerPage] = useState(10);
    const [search, setSearch] = useState('');
    const [filteredElements, setFilteredElements] = useState([]);
    const [isFilter, setIsFilter] = useState(false);
    const [showModal, setShowModale] = useState(false);
    const [showModalAdd, setShowModaleAdd] = useState(false);
    const [dropOffMedico, setDropOffMedico] = useState({
        idMedico: 0,
        isActive: 0,
    });
    const [formData, setFormData] = useState({
        titolo: null,
        url: null,
    });
    // Get current patient
    const indexOfLastElement = currentPage * elementsPerPage;
    const indexOfFirstElement = indexOfLastElement - elementsPerPage;
    let currentElements = isFilter ? filteredElements?.slice(indexOfFirstElement, indexOfLastElement) : elements?.slice(indexOfFirstElement, indexOfLastElement);

   const [forceUpdate, setForceUpdate] = useState(false);
       useEffect(() => {
        fetchWebinar();
       }, []);

    const fetchWebinar = async () => {
        await web.getAll(ENDPOINT.ELENCOWEBINAR)
            .then((response) => {
                if (response.status === 200) {
                    setMedici(response.data.dati);
                }
            }).catch((error) => {

            });
    };

    // Change page
    const paginate = pageNumber => setCurrentPage(pageNumber);
    const handleClose = () => setShowModale(false);
    const handleCloseModalAdd = () => setShowModaleAdd(false);
    const showModalActivePatient = (idUser) => {
        var element = elements.find((x) => x.id === idUser);
        const id = 'id';
        const attivo = 'attivo';
        setDropOffMedico({ ...dropOffMedico, [attivo]: element.attivo, [id]: element.id });
        setShowModale(true)
    }
    const handleChangeModalAdd = () => {
        setFormData({
            titolo: null,
            url: null,
          });
        setShowModaleAdd(true)
    }
    const nextPage = (pageNumber) => {
        setCurrentPage(pageNumber + 1)
    }
    const prevPage = (pageNumber) => {
        setCurrentPage(pageNumber - 1)
    }
    const handleChange = (el) => {
        const inputValue = el.target.value.toLowerCase();
        setSearch({
            search:
                inputValue
        });
        if (inputValue) {
            if (search && search?.search.length >= 1) {
                setIsFilter(true);
                let filteredData = elements.filter(x => String(x.titolo).toLowerCase().includes(inputValue)
                    || String(x.url).toLowerCase().includes(inputValue));
                if (filteredData.length > 0) {
                    setFilteredElements(filteredData);

                } else {
                    setFilteredElements(filteredData);
                }
                setCurrentPage(1)
            } else {
                setFilteredElements(elements);
            }
        } else {
            setFilteredElements(elements);
        }
    };

    const disabilitaMedico = (e) => {
        web.delete(ENDPOINT.ELIMINAWEBINAR, e)
            .then(async (response) => {
                if (response.status === 200 && response.data.statoEsito === 0) {
                    NotificationManager.success("Webinar " + message.SuccessUpdate, entitiesLabels.SUCCESS, 3000);
                    fetchWebinar();
                    setShowModale(false)
                } else {
                    NotificationManager.error(response.data.descrizioneEsito, entitiesLabels.ERROR, 3000);
                }
            }).catch((error) => {
                NotificationManager.error(message.ErrorServer, entitiesLabels.ERROR, 3000);

            })
            .finally(() => {
                fetchWebinar();
            });
    };
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };
    const handleSubmit = (e) => {

        if (validator.current.allValid()) {
            web.post(ENDPOINT.SALVAWEBINAR, formData)
                .then(async (response) => {
                    if (response.status === 200 && response.data.statoEsito === 0) {
                        fetchWebinar();
                        handleCloseModalAdd();
                    } else {
                        NotificationManager.error(response.data.descrizioneEsito, entitiesLabels.ERROR, 3000);
                    }
                }).catch((error) => {
                    NotificationManager.error(message.ErrorServer, entitiesLabels.ERROR, 3000);

                })
                .finally(() => {
                    fetchWebinar();
                });
        } else {
            validator.current.showMessages();
            setForceUpdate(!forceUpdate);
        }
    };
    const validations = {
        titolo: validator.current.message(
            'titolo',
            formData.titolo,
            'required'
        ),
        url: validator.current.message(
            'url',
            formData.url,
            'required'
        ),
    }
    return (<>
        <div className="container" style={{ maxWidth: '600px', margin: '0 auto' }}>
            <h2 className="text-center">Lista Webinar</h2>
        </div>
        <Form.Group className="d-flex mb-3">
            <Form.Control className="me-3" onChange={handleChange} onPaste={handleChange} name="search" alt="medicoDTO" placeholder="Ricerca webinar" value={search.search} />
            <Button variant="primary" onClick={() => handleChangeModalAdd()}>
                Aggiungi Webinar
            </Button>
        </Form.Group>
        <>
            <div className="table-wrapper">
                <table className="table custom">
                    <thead>
                        <tr>
                            <th scope="col">Id </th>
                            <th scope="col">Titolo</th>
                            <th scope="col">Url</th>
                            <th scope="col">action</th>
                        </tr>
                    </thead>
                    <tbody className="rounded-row">
                        {
                            currentElements?.map((pa) => <RowCustom colums={["id", "titolo", "url", "action"]} reference={'id'} elementDelete={'action'} delete={(el) => showModalActivePatient(pa.id)} item={pa} />)
                        }
                    </tbody>
                </table>
            </div>

        </>
        <Pagination
            elementPerPage={elementsPerPage}
            totalElements={isFilter ? filteredElements?.length : elements?.length}
            paginate={paginate}
            currentPage={currentPage}
            prevPage={prevPage}
            nextPage={nextPage}
        />
        <Modal show={showModal} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>{'Elimina Webinar'}</Modal.Title>
            </Modal.Header>
            <Modal.Body>{'Vuoi Eliminare il webinar?'}</Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
                <Button variant="error" onClick={() => disabilitaMedico(dropOffMedico.id)}>
                    {'Elimina'}
                </Button>
            </Modal.Footer>
        </Modal>

        <Modal show={showModalAdd} onHide={handleCloseModalAdd}>
            <Modal.Header closeButton>
                <Modal.Title>{'Aggiungi Webinar'}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row className="mb-3">
                    <Col>
                        <Form.Group controlId="formTitolo">
                            <Form.Label>Titolo:</Form.Label>
                            <Form.Control
                                isInvalid={validations.titolo != null}
                                type="text"
                                name="titolo"
                                value={formData.titolo ? formData.titolo : ''}
                                onChange={handleInputChange}
                            />
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group controlId="formUrl">
                            <Form.Label>Url:</Form.Label>
                            <Form.Control
                                isInvalid={validations.url != null}
                                type="text"
                                name="url"
                                value={formData.url ? formData.url : ''}
                                onChange={handleInputChange}
                            />
                        </Form.Group>
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleCloseModalAdd}>
                    Close
                </Button>
                <Button variant="error" onClick={() => handleSubmit()}>
                    {'Aggiungi'}
                </Button>
            </Modal.Footer>
        </Modal>
        <NotificationContainer></NotificationContainer>
    </>
    );
}













export default ListaWebinar; 