import { Form, Button } from 'react-bootstrap';
import { useState, useEffect } from 'react';
import Pagination from '../CustomComponent/Pagination';
import RowCustom from '../CustomComponent/CustomRow';
import Modal from 'react-bootstrap/Modal';
import { PATH } from '../../helpers/Constants'
import { useNavigate } from 'react-router-dom';
import { web } from '../../helpers/api';
import { isAdmin, isOperatore } from '../../utils';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import { message, entitiesLabels, ENDPOINT } from '../../helpers/Constants';
function ListaPazienti() {
    const navigate = useNavigate();
    const [elements,setPatients] = useState([]);
    // const [loading,] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [elementsPerPage] = useState(10);
    const [search, setSearch] = useState('');
    const [filteredElements, setFilteredElements] = useState([]);
    const [isFilter, setIsFilter] = useState(false);
    const [showModal, setShowModale] = useState(false);
   
    const [dropOffPatient, setDropOffPatient] = useState({
        id: 0,
        isActive: 0,
    });
    useEffect(() => { 
        fetchPatients();
    }, []);
    const fetchPatients = async () => {
        isAdmin() || isOperatore()  ? 
        await web.post( ENDPOINT.ELENCOPAZIENTI  )
            .then((response) => {
                if (response.status === 200) {
                    setPatients(response.data.dati);
                }
            }).catch((error) => {

            })
            :
            await web.post( ENDPOINT.ELENCOPAZIENTIMEDICO, JSON.parse(localStorage.getItem("role")).idUser )
            .then((response) => {
                if (response.status === 200) {
                    setPatients(response.data.dati);
                }
            }).catch((error) => {

            })
};
    // Get current patient
    const indexOfLastElement = currentPage * elementsPerPage;
    const indexOfFirstElement = indexOfLastElement - elementsPerPage;
    let currentElements = isFilter ? filteredElements?.slice(indexOfFirstElement, indexOfLastElement) : elements?.slice(indexOfFirstElement, indexOfLastElement);

    // Change page
    const paginate = pageNumber => setCurrentPage(pageNumber);
    const handleClose = () => setShowModale(false);
    const showModalActivePatient = (idUser) => {
        var element = elements.find((x) => x.idPaziente === idUser);
        const id = 'id';
        const dropOff = 'dropOff';
        setDropOffPatient({ ...dropOffPatient, [dropOff]: element.dropOff , [id]: element.idPaziente  });
        setShowModale(true)
    }

    const nextPage = (pageNumber) => {
        setCurrentPage(pageNumber + 1)
    }
    const prevPage = (pageNumber) => {
        setCurrentPage(pageNumber - 1)
    }
    const handleChange = (el) => {
        const inputValue = el.target.value.toLowerCase();
        setSearch({
            search:
                inputValue
        });
        if (inputValue) {
            if (search && search?.search.length >= 1) {
                setIsFilter(true);
                let filteredData = elements.filter(x => String(x.nome).toLowerCase().includes(inputValue)
                    || String(x.cognome).toLowerCase().includes(inputValue)
                    || String(x.codicePaziente).toLowerCase().includes(inputValue)
                    || String(x.phoneNumber).toLowerCase().includes(inputValue)
                    || String(x.fiscalCode).toLowerCase().includes(inputValue)
                    || String(x.email).toLowerCase().includes(inputValue));
                if (filteredData.length > 0) {
                    setFilteredElements(filteredData);

                } else {
                    setFilteredElements(filteredData);
                }
                setCurrentPage(1)
            } else {
                setFilteredElements(elements);
            }
        } else {
            setFilteredElements(elements);
        }
    };
    const dropOffPaziente = (e) => {
            web.post(ENDPOINT.DROPOFFPAZIENTE, e)
                .then(async (response) => {
                    if (response.status === 200 && response.data.statoEsito === 0) {
                        NotificationManager.success(message.PAZIENTE + message.SuccessUpdate, entitiesLabels.SUCCESS, 3000);
                        fetchPatients();
                        setShowModale(false)
                    }else {
                        NotificationManager.error(response.data.descrizioneEsito, entitiesLabels.ERROR, 3000);
                    }
                }).catch((error) => {
                    NotificationManager.error(message.ErrorServer, entitiesLabels.ERROR, 3000);

                })
                .finally(() => {
                    fetchPatients();
                });
    };
    function toAddPaziente() {

        navigate(PATH.NUOVOPAZIENTE, { replace: true });
    }
    return (<>
        <div className="container" style={{ maxWidth: '600px', margin: '0 auto' }}>
            <h2 className="text-center">Lista Pazienti</h2>
        </div>
        <Form.Group className="d-flex mb-3">
            <Form.Control className="me-3" onChange={handleChange} onPaste={handleChange} name="search" alt="medicoDTO" placeholder="Ricerca Paziente" />
           {
            isAdmin() || isOperatore() ? 
            <Button variant="primary" onClick={() => toAddPaziente()}>
                Aggiungi Paziente
            </Button>:<></>
           } 
        </Form.Group>
        <>
            <div className="table-wrapper">
                <table className="table custom">
                    <thead>
                        <tr key={'1'} >
                            <th  scope="col">Id Paziente</th>
                            <th scope="col">Nome</th>
                            <th  scope="col">Cognome</th>
                            <th  scope="col">Paziente Attivo</th>
                            <th  scope="col">Infermiere a domicilio</th>
                            {isAdmin() || isOperatore() ?  <th key={'5'}  scope="col">Attiva e disattiva</th> :<></>}
                        </tr>
                    </thead>
                    <tbody className="rounded-row">
                        {
                        isAdmin() || isOperatore() ?  
                            currentElements?.map((pa) => <RowCustom  key={pa.idPaziente} colums={["idPaziente", "nome", "cognome", "dropOff", "canAccessEsami", "actions"]} link={'idPaziente'} elementUpdate={"actions"} reference={'idPaziente'} update={(el) => showModalActivePatient(el)} controller={'profilopaziente'} item={pa} />)
                        :
                            currentElements?.map((pa) => <RowCustom  key={pa.idPaziente}  colums={["idPaziente", "nome", "cognome", "dropOff", "canAccessEsami"]} link={'idPaziente'} elementUpdate={"actions"} reference={'idPaziente'} update={(el) => showModalActivePatient(el)} controller={'profilopaziente'} item={pa} />)
                        }
                    </tbody>
                </table>
            </div>

        </>
        <Pagination
            elementPerPage={elementsPerPage}
            totalElements={isFilter ? filteredElements?.length : elements?.length}
            paginate={paginate}
            currentPage={currentPage}
            prevPage={prevPage}
            nextPage={nextPage}
        />
        <Modal show={showModal} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>{dropOffPatient.dropOff ? 'Disattiva Paziente' : 'Attiva Paziente'}</Modal.Title>
            </Modal.Header>
            <Modal.Body>{dropOffPatient.dropOff ? 'Vuoi disattivare il paziente ?' : 'Vuoi riattivare il paziente'}</Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
                <Button variant="primary" onClick={()  => dropOffPaziente(dropOffPatient.id)}>
                    {dropOffPatient.dropOff ? 'Disattiva' : 'Attiva'}
                </Button>
            </Modal.Footer>
        </Modal>
        <NotificationContainer></NotificationContainer>
    </>
    );
}













export default ListaPazienti; 