import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Modal, Button, Row, Col, Form } from 'react-bootstrap';
import { api, web } from '../../helpers/api';
import { IsMedico } from '../../utils';
import { message, entitiesLabels, ENDPOINT } from '../../helpers/Constants';
import Pagination from '../CustomComponent/Pagination';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowCircleLeft, faTrash } from '@fortawesome/free-solid-svg-icons';
import RowCustom from '../CustomComponent/CustomRow';
function Allegati(props) {
    const [showmodalDelete, setShowmodalDelete] = useState(false);
    const [idDelete, setidDelete] = useState(false);
    const [allegati, setAllegati] = useState([]);
    const [esami, setEsami] = useState([]);  // Stato inizializzato come array vuoto
    const [currentPage, setCurrentPage] = useState(1);
    const [elementsPerPage] = useState(5);
    const [filesArray, setFilesArray] = useState([]); // Track the files array
    const imageInputRef = useRef();
    const [modalNewEsame, setModalNewEsame] = useState(false);
    const [modalNewVisita, setModalNewVisita] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);  // Stato per immagine selezionata
    const [showImageModal, setShowImageModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);  // Stato per il modal di conferma eliminazione
    const [deleteId, setDeleteId] = useState(null);  // Stato per memorizzare l'id dell'allegato da eliminare
    const [formData, setFormData] = useState({
        test: '',
        idEsame: props.dettaglioAllegato.idEsame,
        valore: '',
        isOltreSoglia: false
    });

    const fetchAllegati = useCallback(async () => {
        await web.post(ENDPOINT.GETALLEGATOESAME, props.dettaglioAllegato.idEsame)
            .then((response) => {
                if (response.status === 200 && response.data.dati != null) {
                    setAllegati(response.data.dati);
                }
            }).catch((error) => {
                console.error("Error fetching data", error);
            });
    }, [props.dettaglioAllegato.idEsame]);

    useEffect(() => {
        fetchTestEsami();
        fetchAllegati();
    }, [fetchAllegati, props.key]);
    const fetchTestEsami = async () => {
        await web.post(ENDPOINT.GETTESTESAME, props.dettaglioAllegato.idEsame)
            .then((response) => {
                if (response.status === 200 && response.data.dati != null) {
                    setEsami(response.data.dati);
                }
            }).catch((error) => {
                console.error("Error fetching data", error);
            });
    };
    const paginate = pageNumber => setCurrentPage(pageNumber);
    const nextPage = (pageNumber) => {
        setCurrentPage(pageNumber + 1);
    };
    const prevPage = (pageNumber) => {
        setCurrentPage(pageNumber - 1);
    };

    const handleClose = () => { setModalNewVisita(false); setModalNewEsame(false) };

    const saveFileSelected = (e) => {
        const file = e.target.files[0];
        if (file) {
            setFilesArray([file]);
        } else {
            NotificationManager.error("Seleziona un file prima di caricare.", "Errore", 3000);
        }
    };
    const showModalDelete = (id) => {
        setidDelete(id)
        setShowmodalDelete(true)
    }
    const saveDiagnosticTest = () => {
        if (filesArray.length === 0) {
            NotificationManager.error("Nessun file caricato.", "Errore", 3000);
            return;
        }
        const formData = new FormData();
        const file = filesArray[0];
        formData.append("idEsame", props.dettaglioAllegato.idEsame);
        formData.append("nomeFile", file.name);
        formData.append("file", file);

        api.upload(ENDPOINT.UPLOADALLEGATOESAME, formData).then((response) => {
            if (response.status === 200) {
                NotificationManager.success(message.PATIENT + message.SuccessUpdate, entitiesLabels.SUCCESS, 3000);
                fetchAllegati();
                handleClose();
            }
        }).catch((error) => {
            NotificationManager.error(message.ErrorServer, entitiesLabels.ERROR, 3000);
        });
    };

    const openImageModal = (image) => {
        setSelectedImage(image);
        setShowImageModal(true);
    };

    const closeImageModal = () => setShowImageModal(false);

    const openDeleteModal = (id) => {
        setDeleteId(id);
        setShowDeleteModal(true);

    };// Get current patient
    const indexOfLastElement = currentPage * elementsPerPage;
    const indexOfFirstElement = indexOfLastElement - elementsPerPage;
    let currentElements = allegati?.slice(indexOfFirstElement, indexOfLastElement);
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };
    const handleInputChangeSwitch = (e) => {
        var isOltreSoglia = 'isOltreSoglia';
        setFormData({ ...formData, [isOltreSoglia]: formData[isOltreSoglia] ? false : true });
    };
    const closeDeleteModal = () => setShowDeleteModal(false);
    const handleCloseDelete = () => setShowmodalDelete(false);
    const deleteAllegato = () => {
        if (deleteId) {
            web.delete(ENDPOINT.ELIMINAALLEGATO, deleteId)
                .then(response => {
                    if (response.status === 200) {
                        NotificationManager.success(message.SUCCESS, "Eliminazione completata", 3000);
                        fetchAllegati();
                        closeDeleteModal();
                    }
                })
                .catch(error => {
                    NotificationManager.error(message.ErrorServer, entitiesLabels.ERROR, 3000);
                });
        }
    };
    const eliminatest = (e) => {
            web.delete(ENDPOINT.ELIMINATESTESAME, e)
                .then(async (response) => {
                    if (response.status === 200 && response.data.statoEsito === 0) {
                        fetchTestEsami();
                        setShowmodalDelete(false)
                    } else {
                        NotificationManager.error(response.data.descrizioneEsito, entitiesLabels.ERROR, 3000);
                    }
                }).catch((error) => {
                    NotificationManager.error(message.ErrorServer, entitiesLabels.ERROR, 3000);
    
                })
                .finally(() => {
                    fetchTestEsami();
                });
        };
    const handleSubmit = (e) => {

        web.post(ENDPOINT.SALVATESTESAME, formData)
            .then(async (response) => {
                if (response.status === 200 && response.data.statoEsito === 0) {
                    NotificationManager.success(message.RISULTATO + message.SuccessUpdate, entitiesLabels.SUCCESS, 3000);
                    fetchTestEsami();
                } else {
                    NotificationManager.error(response.data.descrizioneEsito, entitiesLabels.ERROR, 3000);
                }
            }).catch((error) => {
                NotificationManager.error(message.ErrorServer, entitiesLabels.ERROR, 3000);

            })
            .finally(() => {
                fetchTestEsami();
            });
    };
    return (
        <div>
            <h2 className="text-center">Tipo di esame: {props.dettaglioAllegato.tipoEsame} del {props.dettaglioAllegato.dataVisita} </h2>

            <Row className='pb-5'>
                <Col className="d-flex justify-content-start">
                    <Button variant="primary" onClick={props.mostralistaAnalisi}>
                        <FontAwesomeIcon icon={faArrowCircleLeft} />
                    </Button>
                </Col>
                {IsMedico() ? <></> :
                    <>
                        <Col className="d-flex justify-content-end">
                            <Button variant="primary" onClick={() => setModalNewVisita(true)}>
                                Carica risultato analisi
                            </Button>
                        </Col>
                        <Col className="d-flex justify-content-end">
                            <Button variant="primary" onClick={() => setModalNewEsame(true)}>
                                Aggiungi risultato analisi
                            </Button>
                        </Col>
                    </>
                }
            </Row>
            <Row>
                <Col xs={5}>
                    <div className="table-wrapper">
                        <table className="table custom">
                            <thead>
                                <tr>
                                    <th>Allegato</th>
                                </tr>
                            </thead>
                            <tbody className="rounded-row">
                                {currentElements.map((item, index) => (
                                    <tr key={index}>
                                        <td style={{ textAlign: 'center', verticalAlign: 'top'}}>
                                            
                                            {item.nomeFile.toLowerCase().endsWith('.pdf') ? (
                                                <a href={`data:application/pdf;base64,${item.byteArrayContent}`} style={{  marginRight:'12px'  }} download>
                                                    Scarica PDF
                                                </a>
                                            ) : (
                                                <img
                                                    src={`data:image/png;base64,${item.byteArrayContent}`}
                                                    alt={`Immagine ${item.esame}`}
                                                    style={{ maxWidth: '100px', height: 'auto', cursor: 'pointer',  marginRight:'12px'  }}
                                                    onClick={() => openImageModal(`data:image/png;base64,${item.byteArrayContent}`)}
                                                />
                                            )}
                                            <Button onClick={() => openDeleteModal(item.id)}  >
                                                <FontAwesomeIcon
                                                    icon={faTrash}
                                                    style={{ cursor: 'pointer', }}
                                                // Apre il modal di eliminazione
                                                />
                                            </Button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        <Pagination
                            elementPerPage={elementsPerPage}
                            totalElements={allegati?.length}
                            paginate={paginate}
                            currentPage={currentPage}
                            prevPage={prevPage}
                            nextPage={nextPage}
                        />
                    </div>
                </Col>
                <Col xs={7}>
                    <div class="table-wrapper">
                        <table class="table custom">
                            <thead>
                                <tr>
                                    <th scope="col">Test</th>
                                    <th scope="col">Valore</th>
                                    <th scope="col">Oltre la soglia</th>
                                    <th scope="col">Elimina</th>
                                </tr>
                            </thead>
                            <tbody className="rounded-row">
                                {esami?.map((pa) => <RowCustom colums={["id", "test", "valore", "isOltreSoglia", "action"]} elementUpdate={"dettaglio"} update={(el) => props.dettaglioEsame(pa)} reference={'idPaziente'} elementDelete={'action'} delete={(el) => showModalDelete(pa.id)} item={pa} />)}
                            </tbody>
                        </table>
                        <Pagination
                            elementPerPage={elementsPerPage}
                            totalElements={esami?.length}
                            paginate={paginate}
                            currentPage={currentPage}
                            prevPage={prevPage}
                            nextPage={nextPage}
                        />
                    </div>
                </Col>
            </Row>
            <Modal show={modalNewEsame} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{'Nuovo risultato '}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col>
                            <Form.Group controlId="formNome">
                                <Form.Label>Risultato :</Form.Label>
                                <Form.Control
                                    //isInvalid={validations.nome != null}
                                    type="text"
                                    name="test"
                                    onChange={handleInputChange}
                                />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group controlId="formNome">
                                <Form.Label>Valore  :</Form.Label>
                                <Form.Control
                                    //isInvalid={validations.nome != null}
                                    type="text"
                                    name="valore"
                                    onChange={handleInputChange}
                                />
                            </Form.Group>

                        </Col>
                        <Col>
                            <Form.Check
                                type="switch"
                                id="custom-switch"
                                label="Valore oltre la soglia"
                                onClick={handleInputChangeSwitch}
                            />
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={() => handleSubmit()}>
                        {'Salva'}
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal show={modalNewVisita} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Nuovo Allegato</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="modal-body align-items-end">
                        <div className="input-group mb-3">
                            <span className="input-group-text">Carica referto</span>
                            <input
                                type="file"
                                className="form-control form-control-sm"
                                ref={imageInputRef}
                                onChange={saveFileSelected}
                                required
                            />
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>Chiudi</Button>
                    <Button variant="primary" onClick={saveDiagnosticTest}>Salva</Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showImageModal} onHide={closeImageModal} size="lg">
                <Modal.Header closeButton />
                <Modal.Body>
                    {selectedImage && (
                        <img
                            src={selectedImage}
                            alt="Immagine a schermo intero"
                            style={{ width: '100%', height: 'auto' }}
                        />
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={closeImageModal}>
                        Chiudi
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* Modal di conferma eliminazione */}
            <Modal show={showDeleteModal} onHide={closeDeleteModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Conferma eliminazione</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Sei sicuro di voler eliminare questo allegato?</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={closeDeleteModal}>Annulla</Button>
                    <Button variant="danger" onClick={deleteAllegato}>Elimina</Button>
                </Modal.Footer>
            </Modal>
            <Modal show={showmodalDelete} onHide={handleCloseDelete}>
                            <Modal.Header closeButton>
                                <Modal.Title>{'Elimina Test'}</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>{'Vuoi eliminare questo test'}</Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={handleCloseDelete}>
                                    Close
                                </Button>
                                <Button variant="primary" onClick={() => eliminatest(idDelete)}>
                                    {'Elimina'}
                                </Button>
                            </Modal.Footer>
                        </Modal>
            <NotificationContainer />
        </div>
    );
}

export default Allegati;
