import React, { useState, useEffect } from 'react';
import { web } from '../../helpers/api';
import { ENDPOINT } from '../../helpers/Constants';
import moment from 'moment';
import { Calendar } from '@nivo/calendar';

function AderenzaTerapia(props) {
    const [data, setData] = useState([]);
    const [anno, setAnno] = useState(moment());  // stato per l'anno

    useEffect(() => {
        // Funzione per ottenere i dati dell'aderenza quando l'anno cambia
        fetchAderenza();
    }, [anno, props.key]);  // Effettua il fetch ogni volta che l'anno cambia

    const fetchAderenza = async () => {
        let id = window.location.pathname.split('/').pop();
        id = parseInt(id);
        await web.post(ENDPOINT.GETALLADERENZA, id)
            .then((response) => {
                if (response.status === 200 && response.data.dati != null) {
                    const nuovaLista = [];
                    response.data.dati.forEach(elemento => {
                        const value = elemento.risposta === "Si" ? 1 : 0;
                        const dataFormattata = moment(elemento.dataInvio).format('YYYY-MM-DD');
                        nuovaLista.push({
                            value: value,
                            day: dataFormattata
                        });
                    });
                    setData(nuovaLista);
                }
            }).catch((error) => {
                console.error("Error fetching data", error);
            });
    };

    // Funzione per incrementare l'anno di 1
    const incrementaAnno = () => {
        setAnno(prevAnno => {
            const nuovoAnno = prevAnno.clone().add(1, 'year');  
            return nuovoAnno;
        });
    };

    // Funzione per diminuire l'anno di 1
    const decrementaAnno = () => {
        setAnno(prevAnno => {
            const nuovoAnno = prevAnno.clone().add(-1, 'year');  
            return nuovoAnno;
        });
    };
    const isCurrentYear = anno.isSame(moment(), 'year');
    return (
        <div className="container" style={{ position: 'absolute' }}>
                <h2 className="text-center">Aderenza Terapia</h2>
                <Calendar
                    key={anno.format('YYYY')}  // Forza il re-render del Calendar quando l'anno cambia
                    data={data}
                    from={anno.format('YYYY')}
                    to={anno.format('YYYY')}
                    width={800}
                    height={300}
                    emptyColor="#EDEDED"
                    colors={['#f88064', '#f88064', '#849474', '#849474']}
                    minValue={0}
                    maxValue={1}
                    margin={{ top: 40, right: 40, bottom: 50, left: 40 }}
                    yearSpacing={5}
                    monthSpacing={5}
                    monthBorderColor="#ffffff"
                    daySpacing={3}
                    dayBorderWidth={2}
                    dayBorderColor="#d9d9d9"
                    legends={[{
                        anchor: 'bottom-right',
                        direction: 'row',
                        translateY: 36,
                        itemCount: 4,
                        itemWidth: 42,
                        itemHeight: 36,
                        itemsSpacing: 14,
                        itemDirection: 'right-to-left'
                    }]}
                />

            <ul className="pagination justify-content-center">
                <li className="page-item">
                    <p className="page-link prev" onClick={decrementaAnno}>Previous</p>
                </li>
                <li className="page-item">
                    <p 
                        className={`page-link next ${isCurrentYear ? 'disabled' : ''}`} 
                        onClick={isCurrentYear ? null : incrementaAnno}
                    >
                        Next
                    </p>
                </li>
            </ul>
        </div>
    );
}

export default AderenzaTerapia;
