import React, { useState, useRef, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import SimpleReactValidator from 'simple-react-validator';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import { web, user } from '../../helpers/api';
import { message, entitiesLabels, ENDPOINT } from '../../helpers/Constants';
import { IsMedico, isOperatore ,isAdmin} from '../../utils';
function Profile() {
    const validator = useRef(new SimpleReactValidator())
    const [formData, setFormData] = useState({
        nome: JSON.parse(localStorage.getItem("role")).nome,
        cognome: JSON.parse(localStorage.getItem("role")).cognome,
        email: JSON.parse(localStorage.getItem("role")).username,
        telefono: '',
        numeroTelefono:''
    });
    const [forceUpdate, setForceUpdate] = useState(false);
    useEffect(() => {
        fetchProfile();
    }, []);
    const fetchProfile = async () => {
        let id = JSON.parse(localStorage.getItem("role")).idEntita;
        id = parseInt(id);
        if (id && IsMedico()) {
            await web.post(ENDPOINT.DETTAGLIOMEDICO, id)
                .then((response) => {
                    if (response.status === 200 && response.data.dati != null) {
                        setFormData(response.data.dati);
                        localStorage.setItem('nominativo', JSON.stringify(response.data.dati.nome + " " + response.data.dati.cognome));
                    }
                }).catch((error) => {

                });
        }
        if (id && isOperatore()) {
            await web.post(ENDPOINT.DETTAGLIOOPERATORE, id)
                .then((response) => {
                    if (response.status === 200 && response.data.dati != null) {
                        setFormData(response.data.dati);
                    }
                }).catch((error) => {

                });
        }
    };
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = (e) => {

        if (validator.current.allValid()) {
            user.post(ENDPOINT.MODIFICAPROFILO, { nome: formData.nome, cognome: formData.cognome, telefono: formData.numeroTelefono ? formData.numeroTelefono: formData.telefono })
                .then(async (response) => {
                    if (response.status === 200 && response.data.statoEsito === 0) {


                    } else {
                        NotificationManager.error(response.data.descrizioneEsito, entitiesLabels.ERROR, 3000);
                    }
                }).catch((error) => {
                    NotificationManager.error(message.ErrorServer, entitiesLabels.ERROR, 3000);

                })
                .finally(() => {
                });
        } else {
            validator.current.showMessages();
            setForceUpdate(!forceUpdate);
        }
    };
    const validations = {
        nome: validator.current.message(
            'nome',
            formData.nome,
            'required'
        ),
        cognome: validator.current.message(
            'cognome',
            formData.cognome,
            'required'
        ),
        numeroTelefono: validator.current.message(
            'numeroTelefono',
            formData.numeroTelefono || formData.telefono,
            'required|number'
        ),
    }


    return (
        <div className="container" style={{ maxWidth: '600px', margin: '0 auto' }}>
            {!isAdmin() ? <>
                <h2 className="text-center">Modifica Profilo</h2>
                <Row className="mb-3">
                    <Col>
                        <Form.Group controlId="formNome">
                            <Form.Label>Nome:</Form.Label>
                            <Form.Control
                                isInvalid={validations.nome != null}
                                type="text"
                                name="nome"
                                value={formData.nome ? formData.nome : ''}
                                onChange={handleInputChange}
                            />
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group controlId="formCognome">
                            <Form.Label>Cognome:</Form.Label>
                            <Form.Control
                                isInvalid={validations.cognome != null}
                                type="text"
                                name="cognome"
                                value={formData.cognome ? formData.cognome : ''}
                                onChange={handleInputChange}
                            />
                        </Form.Group>
                    </Col>
                </Row>
            </> : <><h2 className="text-center">Profilo Amministratore</h2></>}
            <Row className="mb-3">
                <Col>
                    <Form.Group className="mb-3" controlId="formEmail">
                        <Form.Label>Email:</Form.Label>
                        <Form.Control
                            disabled={true}
                            type="email"
                            name="email"
                            value={formData.email ? formData.email : ''}
                            onChange={handleInputChange}
                        />
                    </Form.Group>
                </Col>
            </Row>
            {!isAdmin() ? <>
                <Row className="mb-3">
                  {formData.numeroTelefono  ?
                  
                  <Col>
                  <Form.Group className="mb-3" controlId="">
                      <Form.Label>Telefono :</Form.Label>
                      <div className="position-relative">
                          <Form.Control
                              isInvalid={validations.telefono != null}
                              type={'text'}
                              name="numeroTelefono"
                              value={formData.numeroTelefono  ? formData.numeroTelefono : ''}
                              onChange={handleInputChange}
                          />
                      </div>
                  </Form.Group>
              </Col>:
              <Col>
              <Form.Group className="mb-3" controlId="">
                  <Form.Label>Telefono :</Form.Label>
                  <div className="position-relative">
                      <Form.Control
                          isInvalid={validations.telefono != null}
                          type={'text'}
                          name="telefono"
                          value={formData.telefono  ? formData.telefono : ''}
                          onChange={handleInputChange}
                      />
                  </div>
              </Form.Group>
          </Col>
                }  
                
            </Row>
            <Button onClick={() => handleSubmit()} variant="primary" className="w-100">Modifica Profilo</Button>
            </> :<></>}
            < NotificationContainer />
        </div>
    );
}

export default Profile;