import React, { useState, useEffect } from 'react';
import { web } from '../../helpers/api';
import { ENDPOINT } from '../../helpers/Constants';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import RangeSlider from 'react-bootstrap-range-slider';
function Feedback(props) {
    const [domande, setDomande] = useState([]);  // Initialize as an empty array
    const [risposte, setRisposte] = useState([]);  // Initialize as an empty array

    useEffect(() => {
        // Funzione per ottenere i dati dell'aderenza quando l'anno cambia
        fetchAderenza();
        fetchRisposte();
    }, [props.key]);  // Fetch data on component mount

    const fetchAderenza = async () => {
        let id = window.location.pathname.split('/').pop();
        id = parseInt(id);
        await web.post(ENDPOINT.GETALLDOMANDEFEEDBACK, id)
            .then((response) => {
                if (response.status === 200 && response.data.dati != null) {
                    setDomande(Array.isArray(response.data.dati) ? response.data.dati : []);
                }
            }).catch((error) => {
                console.error("Error fetching data", error);
            });
    };

    const fetchRisposte = async () => {
        let id = window.location.pathname.split('/').pop();
        id = parseInt(id);
        await web.post(ENDPOINT.GETALLRISPOSTEFEEDBACK, id)
            .then((response) => {
                if (response.status === 200 && response.data.dati != null) {
                    setRisposte(Array.isArray(response.data.dati) ? response.data.dati : []);
                }
            }).catch((error) => {
                console.error("Error fetching data", error);
            });
    };

    const handleSliderChange = async (idDomandaFeedback, valutazione) => {

        setRisposte((prevRisposte) => 
            prevRisposte.map((risposta) =>
                risposta.idDomandaFeedback === idDomandaFeedback
                    ? { ...risposta, valutazione } 
                    : risposta 
            )
        );
        let id = window.location.pathname.split('/').pop();
        id = parseInt(id);
        await web.post(ENDPOINT.SALVAFEEDBACK, { idPaziente: id, idDomandaFeedback, valutazione })
            .then((response) => {
                if (response.status === 200 && response.data.dati != null) {
                   
                }
            }).catch((error) => {
                console.error("Error saving feedback", error);
            });
    };

    

    return (
        <>
            <div>
                <h2 className="text-center">Gradimento</h2>
            </div>
            {Array.isArray(domande) && domande.map((item, index) => {
                return (
                    <Row key={index}>
                        <Col xs="4"><h6>{item.testo}</h6></Col>
                        <Col xs="7">
                            <RangeSlider
                            tooltip='on'
                            tooltipPlacement='top'
                                min={1} 
                                max={5}
                                 variant='secondary'
                                value={risposte.find(x => x.idDomandaFeedback === item.id) != null? risposte.find(x => x.idDomandaFeedback === item.id)?.valutazione: 1}
                                onChange={(e) => handleSliderChange(item.id, parseInt(e.target.value))}
                            />
                        </Col >
                    </Row>
                );
            })}
        </>
    );
}

export default Feedback;
