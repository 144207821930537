import { useState, useEffect } from 'react';
import Pagination from '../CustomComponent/Pagination';
import RowCustom from '../CustomComponent/CustomRow';
import {  ENDPOINT } from '../../helpers/Constants'
import { web } from '../../helpers/api';
function QuestionariUser(props) {
    const [elements, setQuestionari] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [elementsPerPage] = useState(10);
    const [filteredElements] = useState([]);
    const [isFilter] = useState(false);

    useEffect(() => {
        fetchQuestionari();
    }, [props.key]);
    const fetchQuestionari = async () => {
        let id = window.location.pathname.split('/').pop();
        id = parseInt(id);
        if (id) {
            await web.post(ENDPOINT.ELENCOQUESTIONARIPAZIENTE, id)
                .then((response) => {
                    if (response.status === 200) {
                        setQuestionari(response.data.dati);
                    }
                }).catch((error) => {

                })
        }
    };
    const indexOfLastElement = currentPage * elementsPerPage;
    const indexOfFirstElement = indexOfLastElement - elementsPerPage;
    let currentElements = isFilter ? filteredElements?.slice(indexOfFirstElement, indexOfLastElement) : elements?.slice(indexOfFirstElement, indexOfLastElement);

    const paginate = pageNumber => setCurrentPage(pageNumber);

    const nextPage = (pageNumber) => {
        setCurrentPage(pageNumber + 1)
    }
    const prevPage = (pageNumber) => {
        setCurrentPage(pageNumber - 1)
    }

    return (<>
        <div className="container" style={{ maxWidth: '600px', margin: '0 auto' }}>
            <h2 className="text-center">Questionari Associati</h2>
        </div>
        <>
            <div className="table-wrapper">
                <table className="table custom">
                    <thead>
                        <tr>
                            <th scope="col">Titolo</th>
                        </tr>
                    </thead>
                    <tbody className="rounded-row">
                        {
                            currentElements?.map((pa) => <RowCustom colums={["titolo"]} item={pa} />)
                        }
                    </tbody>
                </table>
            </div>
        </>
        <Pagination
            elementPerPage={elementsPerPage}
            totalElements={isFilter ? filteredElements?.length : elements?.length}
            paginate={paginate}
            currentPage={currentPage}
            prevPage={prevPage}
            nextPage={nextPage}
        />
    </>
    );
}
export default QuestionariUser; 