import { BrowserRouter, Routes, Route } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import Splash from '../Pages/PreLogin/Splash';
import Login from '../Pages/PreLogin/Login';
import Recovery from '../Pages/PreLogin/Recovery';
import Profile from '../Pages/Profile/Profile';
import Dashboard from '../Pages/Dashboard/Dashboard';
import TabQuestionario from '../Pages/Questionario/TabQuestionario';
import RegistrazioneMedico from '../Pages/Registrazione/RegistrazioneMedico';
import ListaWebinar from '../Pages/Tabelle/ListaWebinar';
import RegistrazionePaziente from '../Pages/Registrazione/RegistrazionePaziente';
import RegistrazioneOperatore from '../Pages/Registrazione/RegistrazioneOperatore';
import RegistrazioneCentroMedico from '../Pages/Registrazione/RegistrazioneCentroMedico';
import ListaOperatori from '../Pages/Tabelle/ListaOperatori';
import ListaMedici from '../Pages/Tabelle/ListaMedici';
import ListaCentriMedici from '../Pages/Tabelle/ListaCentriMedici';
import ListaPazienti from '../Pages/Tabelle/ListaPazienti';
import ListaQuestionari from '../Pages/Tabelle/ListaQuestionari';
import TabsPaziente from '../Pages/Paziente/TabsPaziente';
import Navbar from './Navbar';
import { isPreLogin, checkIsUserLogin } from '../utils'
import { PATH } from '../helpers/Constants';
function App() {
  const [url] = useState(window.location.pathname);
  useEffect(() => {
    document.body.className = isPreLogin() ? "contenuto-dasboard " : "splash custom-login";
     checkIsUserLogin()
  }, [url]);


return (
  <div className="wrapper" >
    <div className="container-lg page-wrapper">
      <div className="row flex-nowrap">
        <BrowserRouter>
          <Navbar />
          <div role="main" className="col">
            <Routes>
              <Route path={PATH.SPLASH} element={<Splash />} />
              <Route path={PATH.LOGIN} element={<Login />} />
              <Route path={PATH.RECOVERY} element={<Recovery />} />
              <Route path={PATH.MODELLO} element={<TabQuestionario />} />
              <Route path={PATH.DASCHBOARD} element={<Dashboard />} />
              <Route path={PATH.DETTAGLIOMODELLO} element={< TabQuestionario/>} />
              <Route path={PATH.REGISTRAZIONEMEDICO} element={<RegistrazioneMedico />} />
              <Route path={PATH.PROFILOMEDICO} element={<RegistrazioneMedico />} />
              <Route path={PATH.LISTAWEBINAR} element={<ListaWebinar />} />
              <Route path={PATH.REGISTRAZIONEOPERATORE} element={<RegistrazioneOperatore />} />
              <Route path={PATH.PROFILOOPERATORE} element={<RegistrazioneOperatore />} />
              <Route path={PATH.LISTAOPERATORI} element={<ListaOperatori />} />
              <Route path={PATH.LISTAMEDICI} element={<ListaMedici />} />
              <Route path={PATH.LISTACENTRIMEDICI} element={<ListaCentriMedici />} />
              <Route path={PATH.LISTAPAZIENTI} element={<ListaPazienti />} />
              <Route path={PATH.LISTAMODELLI} element={<ListaQuestionari />} />
              <Route path={PATH.PROFILOPAZIENTE} element={<TabsPaziente />} />
              <Route path={PATH.NUOVOPAZIENTE} element={<RegistrazionePaziente />} />
              <Route path={PATH.PROFILOCENTROMEDICO} element={<RegistrazioneCentroMedico />} />
              <Route path={PATH.NUOVOCENTROMEDICO} element={<RegistrazioneCentroMedico />} />
              <Route path={PATH.NUOVOOPERATORE} element={<RegistrazioneOperatore />} />
              <Route path={PATH.PROFILO} element={<Profile />} />
            </Routes>
          </div>
        </BrowserRouter>
      </div>
    </div>
  </div>
);
}

export default App;