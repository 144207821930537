import React, { useState, useEffect } from 'react';
import { Modal, Button, Row, Col, Form } from 'react-bootstrap';
import { web } from '../../helpers/api';
import { message, entitiesLabels, ENDPOINT } from '../../helpers/Constants';
import Pagination from '../CustomComponent/Pagination';
import RowCustom from '../CustomComponent/CustomRow';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {  faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
function Visite(props) {
    const [visite, setVisite] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [elementsPerPage] = useState(10);
    const [modalNewVisita, setModalNewVisita] = useState(false);
    const [showmodalDelete, setShowmodalDelete] = useState(false);
    const [idDelete, setidDelete] = useState(false);
    const [minDate] = useState(new Date());
    const [formData, setFormData] = useState({
        idVisita:0,
        dataVisita:'',
        idPaziente:parseInt(window.location.pathname.split('/').pop()),
        idTipoVisita:0
    });
    useEffect(() => {
        fetchVisite();
    }, [props.key]);

    const fetchVisite = async () => {
        let id = window.location.pathname.split('/').pop();
        id = parseInt(id);
        await web.post(ENDPOINT.GETALLVISITE, id)
            .then((response) => {
                if (response.status === 200 && response.data.dati != null) {
                    setVisite(response.data.dati);
                }
            }).catch((error) => {
                console.error("Error fetching data", error);
            });
    };
    const paginate = pageNumber => setCurrentPage(pageNumber);
    const nextPage = (pageNumber) => {
        setCurrentPage(pageNumber + 1)
    }
    const prevPage = (pageNumber) => {
        setCurrentPage(pageNumber - 1)
    }
    const showModalActivePatient = (idUser) => {
        // var element = elements.find((x) => x.idPaziente === idUser);
        // const id = 'id';
        // const dropOff = 'dropOff';
        // setDropOffPatient({ ...dropOffPatient, [dropOff]: element.dropOff , [id]: element.idPaziente  });
        // setShowModale(true)
    }
    const showModalDelete = (id) => {
        setidDelete(id)
        setShowmodalDelete(true)
    }
    const handleCloseDelete = () => setShowmodalDelete(false);
    const handleDateChange = (date) => {
        const { name, value } = date.target;
        setFormData({ ...formData, [name]: value });
    }
    const handleInputTipoVisita = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: parseInt(value) });
    };
    const handleClose = () => setModalNewVisita(false);

    const handleSubmit = (e) => {

            web.post(ENDPOINT.SALVAVISITA, formData)
                .then(async (response) => {
                    if (response.status === 200 && response.data.statoEsito === 0) {
                        NotificationManager.success(message.VISITA + message.SuccessUpdate, entitiesLabels.SUCCESS, 3000);
                        fetchVisite();
                    } else {
                        NotificationManager.error(response.data.descrizioneEsito, entitiesLabels.ERROR, 3000);
                    }
                }).catch((error) => {
                    NotificationManager.error(message.ErrorServer, entitiesLabels.ERROR, 3000);

                })
                .finally(() => {
                    fetchVisite();
                });
    };
    const eliminaevisita= (e) => {
            web.delete(ENDPOINT.ELIMINAVISITA, e)
                .then(async (response) => {
                    if (response.status === 200 && response.data.statoEsito === 0) {
                        fetchVisite();
                        setShowmodalDelete(false)
                    } else {
                        NotificationManager.error(response.data.descrizioneEsito, entitiesLabels.ERROR, 3000);
                    }
                }).catch((error) => {
                    NotificationManager.error(message.ErrorServer, entitiesLabels.ERROR, 3000);
                })
                .finally(() => {
                    fetchVisite();
                });
        };
    return (
        <div>
            <h2 className="text-center">Visite</h2>
            <Row className='pb-5'>
                <Col className="d-flex justify-content-end">
                    <Button variant="primary" onClick={() => setModalNewVisita(true)}>
                        Aggiungi Visita
                    </Button>
                </Col>
            </Row>
            <div class="table-wrapper">
                <table class="table custom">
                    <thead>
                        <tr>
                            <th scope="col">Visita Numero</th>
                            <th scope="col">Data Visita</th>
                            <th scope="col">Tipo Visita</th>
                            <th scope="col">Dettaglio</th>
                            <th scope="col">Elimina</th>
                        </tr>
                    </thead>
                    <tbody className="rounded-row">
                        {visite?.map((pa) => <RowCustom colums={["idVisita", "dataVisita", "tipoVisita", "dettaglio" ,"action"]} elementUpdate={"dettaglio"} update={(el) => props.dettaglioEsame(pa)}  reference={'idPaziente'} elementDelete={'action'} delete={(el) => showModalDelete(pa.idVisita)} item={pa} customIconUpdate={<FontAwesomeIcon icon={faMagnifyingGlass} className='c-icon-w'/>} />)}
                    </tbody>
                </table>
                <Pagination
                    elementPerPage={elementsPerPage}
                    totalElements={visite?.length}
                    paginate={paginate}
                    currentPage={currentPage}
                    prevPage={prevPage}
                    nextPage={nextPage}
                />
            </div>
            <Modal show={modalNewVisita} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{'Nuova Visita'}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <div className="col-12 ">
                            <span className=".btn-primary" id="label-inizio">Data  Visita:</span>
                            <div className="input-group mb-3">
                                <input type="datetime-local" min={minDate.toISOString().split('T')[0]} className="form-control form-control-sm" id="dataVisita" name="dataVisita" aria-describedby="label-inizio" onChange={handleDateChange} defaultValue={moment().format("YYYY-MM-DD")} />
                            </div>
                        </div>
                    </Row>
                    <Row>
                        <Form.Group className="mb-3" >
                            <Form.Label>Tipo di visita:</Form.Label>
                            <Form.Select
                                className={''}
                                name="idTipoVisita"
                                onChange={handleInputTipoVisita}
                            >
                                <option value="0">Seleziona tipo di visita</option>
                                <option value="1">Visita Medica</option>
                                <option value="2">Visita Infermiere</option>
                            </Form.Select>
                        </Form.Group>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="primary"  onClick={() => handleSubmit()}>
                        {'Salva'}
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal show={showmodalDelete} onHide={handleCloseDelete}>
                <Modal.Header closeButton>
                    <Modal.Title>{'Elimina Visita'}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{'Vuoi eliminare visita?'}</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseDelete}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={() => eliminaevisita(idDelete)}>
                        {'Elimina'}
                    </Button>
                </Modal.Footer>
            </Modal>
            <NotificationContainer></NotificationContainer>
        </div>
        
    );
}

export default Visite;
