import { Form, Button } from 'react-bootstrap';
import { useState, useEffect } from 'react';
import Pagination from '../CustomComponent/Pagination';
import RowCustom from '../CustomComponent/CustomRow';
import Modal from 'react-bootstrap/Modal';
import { PATH } from '../../helpers/Constants'
import { useNavigate } from 'react-router-dom';
import { web } from '../../helpers/api';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import { message, entitiesLabels, ENDPOINT } from '../../helpers/Constants';
function ListaMedici() {
  const navigate = useNavigate();
  const [elements, setMedici] = useState([]);
  // const [loading,] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [elementsPerPage] = useState(10);
  const [search, setSearch] = useState('');
  const [filteredElements, setFilteredElements] = useState([]);
  const [isFilter, setIsFilter] = useState(false);
  const [showModal, setShowModale] = useState(false);
  const [dropOffMedico, setDropOffMedico] = useState({
    idMedico: 0,
    isActive: 0,
  });

  // Get current patient
  const indexOfLastElement = currentPage * elementsPerPage;
  const indexOfFirstElement = indexOfLastElement - elementsPerPage;
  let currentElements = isFilter ? filteredElements?.slice(indexOfFirstElement, indexOfLastElement) : elements?.slice(indexOfFirstElement, indexOfLastElement);

  useEffect(() => {
    fetchMedici();
  }, []);

  const fetchMedici = async () => {
    await web.post(ENDPOINT.ELENCOMEDICI)
      .then((response) => {
        if (response.status === 200) {
          setMedici(response.data.dati);
        }
      }).catch((error) => {

      });
  };

  // Change page
  const paginate = pageNumber => setCurrentPage(pageNumber);
  const handleClose = () => setShowModale(false);
  const showModalActivePatient = (idUser) => {
    var element = elements.find((x) => x.idMedico === idUser);
    const idMedico = 'idMedico';
    const attivo = 'attivo';
    setDropOffMedico({ ...dropOffMedico, [attivo]: element.attivo , [idMedico]: element.idMedico });
    setShowModale(true)
  }

  const nextPage = (pageNumber) => {
    setCurrentPage(pageNumber + 1)
  }
  const prevPage = (pageNumber) => {
    setCurrentPage(pageNumber - 1)
  }
  const handleChange = (el) => {
    const inputValue = el.target.value.toLowerCase();
    setSearch({
      search:
        inputValue
    });
    if (inputValue) {
      if (search && search?.search.length >= 1) {
        setIsFilter(true);
        let filteredData = elements.filter(x => String(x.nome).toLowerCase().includes(inputValue)
          || String(x.cognome).toLowerCase().includes(inputValue)
          || String(x.codiceMedico).toLowerCase().includes(inputValue)
          || String(x.centroMedico).toLowerCase().includes(inputValue));
        if (filteredData.length > 0) {
          setFilteredElements(filteredData);

        } else {
          setFilteredElements(filteredData);
        }
        setCurrentPage(1)
      } else {
        setFilteredElements(elements);
      }
    } else {
      setFilteredElements(elements);
    }
  };

  const disabilitaMedico = (e) => {
    web.post(ENDPOINT.DISABILITAMEDICO, e)
      .then(async (response) => {
        if (response.status === 200 && response.data.statoEsito === 0) {
          NotificationManager.success(message.MEDICO + message.SuccessUpdate, entitiesLabels.SUCCESS, 3000);
          fetchMedici();
          setShowModale(false)
        } else {
          NotificationManager.error(response.data.descrizioneEsito, entitiesLabels.ERROR, 3000);
        }
      }).catch((error) => {
        NotificationManager.error(message.ErrorServer, entitiesLabels.ERROR, 3000);

      })
      .finally(() => {
        fetchMedici();
      });
  };

  function toAddMedico() {

    navigate(PATH.REGISTRAZIONEMEDICO, { replace: true });
  }
  return (<>
    <div className="container" style={{ maxWidth: '600px', margin: '0 auto' }}>
      <h2 className="text-center">Lista Medici</h2>
    </div>
    <Form.Group className="d-flex mb-3">
      <Form.Control className="me-3" onChange={handleChange} onPaste={handleChange} name="search" alt="medicoDTO" placeholder="Ricerca Medico" value={search.search} />
      <Button variant="primary" onClick={() => toAddMedico()}>
        Aggiungi Medico
      </Button>
    </Form.Group>
    <>
      <div className="table-wrapper">
        <table className="table custom">
          <thead>
            <tr>
              <th scope="col">Id Medico</th>
              <th scope="col">Nome</th>
              <th scope="col">Cognome</th>
              <th scope="col">Codice Medico</th>
              <th scope="col">Centro Medico</th>
              <th scope="col">Medico Attivo</th>
              <th scope="col">Attiva e disattiva</th>
            </tr>
          </thead>
          <tbody className="rounded-row">
            {
              currentElements?.map((pa) => <RowCustom colums={["idMedico", "nome", "cognome", "codiceMedico", "centroMedico", "attivo", "actions"]} link={'idMedico'} elementUpdate={"actions"} reference={'idMedico'} controller={'profilomedico'} update={(el) => showModalActivePatient(el)} item={pa} />)
            }
          </tbody>
        </table>
      </div>

    </>
    <Pagination
      elementPerPage={elementsPerPage}
      totalElements={isFilter ? filteredElements?.length : elements?.length}
      paginate={paginate}
      currentPage={currentPage}
      prevPage={prevPage}
      nextPage={nextPage}
    />
    <Modal show={showModal} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{dropOffMedico.attivo ? 'Disattiva Medico' : 'Attiva Medico'}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{dropOffMedico.attivo ? 'Vuoi disattivare il medico ?' : 'Vuoi riattivare il medico'}</Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button variant="primary" onClick={() => disabilitaMedico(dropOffMedico.idMedico)}>
          {dropOffMedico.attivo ? 'Disattiva' : 'Attiva'}
        </Button>
      </Modal.Footer>
    </Modal>
    <NotificationContainer></NotificationContainer>
  </>
  );
}













export default ListaMedici; 