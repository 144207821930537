import { useEffect, useState } from 'react';
import { checkIsUserLogin, isAdmin, isOperatore, isSystem, IsMedico } from '../utils';
import { PATH } from '../helpers/Constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import RancareIcon from '../img/output.png';
import { faUserTie, faUsersLine, faUserDoctor, faHospital, faCommentDots, faSitemap, faSignOut, faLineChart } from '@fortawesome/free-solid-svg-icons';
const SideNav = () => {
    const [url] = useState(window.location.pathname);
    useEffect(() => {
        checkIsUserLogin()
    }, [url]);

    return (
        <>
            {checkIsUserLogin() ?
                <header className="col b-r-navbar">
                    <a href={PATH.SPLASH} className="logo"><img src={RancareIcon} alt="Logo" height={100} /></a>
                    <button className="menu-toggle d-block d-lg-none ">
                        <span />
                        <span />
                        <span />
                    </button>
                    <ul className="nav flex-column main-menu">
                        <li className="nav-item">
                            <a href={PATH.PROFILO} className="nav-link profilo-paziente-nome" aria-current="page" >
                                <p>
                                    <FontAwesomeIcon icon={faUserTie} />  {JSON.parse(localStorage.getItem("nominativo")).toString()}
                                </p>
                                <p>
                                    {isAdmin() ? "Amministratore" : isOperatore() ? "Operatore" : IsMedico() ? "Medico" : ""}
                                </p>
                            </a>
                        </li>
                        <hr />
                        {isOperatore() || isAdmin() || isSystem() ?
                            <>
                                <li className="nav-item">
                                    <a href={PATH.DASCHBOARD} className="nav-link" aria-current="page" >
                                        <p>
                                            <FontAwesomeIcon icon={faLineChart} />    Dashboard
                                        </p>
                                    </a>
                                </li>
                            </>:<></>}
                        <li className="nav-item">
                            <a href={PATH.LISTAPAZIENTI} className="nav-link elenco-pazienti" aria-current="page" >
                                <p>
                                    <FontAwesomeIcon icon={faUsersLine} />    Elenco Pazienti
                                </p>
                            </a>
                        </li>
                        <li className="nav-item">
                            <a href={PATH.LISTAWEBINAR} className="nav-link elenco-pazienti" aria-current="page" >
                                <p>
                                    <FontAwesomeIcon icon={faUsersLine} />   Elenco Webinar
                                </p>
                            </a>
                        </li>
                        {isOperatore() || isAdmin() || isSystem() ?
                            <>
                                <li className="nav-item">
                                    <a href={PATH.LISTAMEDICI} className="nav-link nuovo-paziente" aria-current="page" >
                                        <p>
                                            <FontAwesomeIcon icon={faUserDoctor} />     Elenco Medici
                                        </p>
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a href={PATH.LISTACENTRIMEDICI} className="nav-link nuovo-centroMedico" aria-current="page" >
                                        <p>
                                            <FontAwesomeIcon icon={faHospital} />   Elenco Centri Medici
                                        </p>
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a href={PATH.LISTAMODELLI} className="nav-link no-kit" aria-current="page" >
                                        <p>
                                            <FontAwesomeIcon icon={faCommentDots} />  Lista Modelli Questionario
                                        </p>
                                    </a>
                                </li> </> : <></>}
                        {isAdmin() ?
                            <>
                                <li className="nav-item">
                                    <a href={PATH.LISTAOPERATORI} className="nav-link profilo-paziente" aria-current="page" >
                                        <p>
                                            <FontAwesomeIcon icon={faSitemap} />  Lista Operatori
                                        </p>
                                    </a>
                                </li>
                            </> : <></>}
                        <li className="nav-item">
                            <a href="/" className="nav-link logout" >
                                <p>
                                    <FontAwesomeIcon icon={faSignOut} />  Logout
                                </p>
                            </a>
                        </li>
                    </ul>
                    <p className="">
                        Progetto <br />a cura di
                    </p>
                    <br />
                    <p className="">
                        Realizzato con il contributo di
                    </p>
                    <br />

                    <p className="">
                        Con il <br />patrocinio di
                    </p>
                </header>
                : <></>
            }
        </>
    )
}

export default SideNav
