import React, { useState, useEffect } from 'react';
import { Modal, Button, Row, Col, Form } from 'react-bootstrap';
import { web } from '../../helpers/api';
import { message, entitiesLabels, ENDPOINT } from '../../helpers/Constants';
import Pagination from '../CustomComponent/Pagination';
import RowCustom from '../CustomComponent/CustomRow';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowCircleLeft, faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
function Esame(props) {
    const [esami, setEsami] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [elementsPerPage] = useState(10);
    const [modalNewEsame, setModalNewEsame] = useState(false);
    const [showmodalDelete, setShowmodalDelete] = useState(false);
    const [idDelete, setidDelete] = useState(false);
    const [formData, setFormData] = useState({
        idVisita:props.dettaglioVisita.idVisita,
        idTipoEsame:0
    });
    useEffect(() => {
        fetchEsami();
    }, [props.key]);
    const fetchEsami = async () => {
        await web.post(ENDPOINT.GETESAMEVISITA, props.dettaglioVisita.idVisita)
            .then((response) => {
                if (response.status === 200 && response.data.dati != null) {
                    setEsami(response.data.dati);
                }
            }).catch((error) => {
                console.error("Error fetching data", error);
            });
    };
    
    const paginate = pageNumber => setCurrentPage(pageNumber);
    const nextPage = (pageNumber) => {
        setCurrentPage(pageNumber + 1)
    }
    const prevPage = (pageNumber) => {
        setCurrentPage(pageNumber - 1)
    }
    const showModalDelete = (id) => {
        setidDelete(id)
        setShowmodalDelete(true)
    }
    const handleInputTipoVisita = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: parseInt(value) });
    };
    const handleClose = () => setModalNewEsame(false);
    const handleCloseDelete = () => setShowmodalDelete(false);
    const handleSubmit = (e) => {

            web.post(ENDPOINT.SALVAESAME, formData)
                .then(async (response) => {
                    if (response.status === 200 && response.data.statoEsito === 0) {
                        NotificationManager.success(message.ESAME + message.SuccessUpdate, entitiesLabels.SUCCESS, 3000);
                        fetchEsami();
                    } else {
                        NotificationManager.error(response.data.descrizioneEsito, entitiesLabels.ERROR, 3000);
                    }
                }).catch((error) => {
                    NotificationManager.error(message.ErrorServer, entitiesLabels.ERROR, 3000);
                })
                .finally(() => {
                    fetchEsami();
                });
    };
    const eliminaesame = (e) => {
        web.delete(ENDPOINT.ELIMINAESAME, e)
            .then(async (response) => {
                if (response.status === 200 && response.data.statoEsito === 0) {
                    fetchEsami();
                    setShowmodalDelete(false)
                } else {
                    NotificationManager.error(response.data.descrizioneEsito, entitiesLabels.ERROR, 3000);
                }
            }).catch((error) => {
                NotificationManager.error(message.ErrorServer, entitiesLabels.ERROR, 3000);
            })
            .finally(() => {
                fetchEsami();
            });
    };
    return (
        <div>
            <h2 className="text-center"> {props.dettaglioVisita.tipoVisita} {props.dettaglioVisita.dataVisita}</h2>
            <Row className='pb-5'>
            <Col className="d-flex justify-content-start">
                    <Button variant="primary" onClick={props.mostralistaVisite}>
                    <FontAwesomeIcon icon={faArrowCircleLeft} className=''/>
                    </Button>
                </Col>
                <Col className="d-flex justify-content-end">
                    <Button variant="primary" onClick={() => setModalNewEsame(true)}>
                        Aggiungi Esame
                    </Button>
                </Col>
            </Row>
            <div class="table-wrapper">
                <table class="table custom">
                    <thead>
                        <tr>
                            <th scope="col">Esame numero</th>
                            <th scope="col">Data Esame</th>
                            <th scope="col">Tipo di esame</th>
                            <th scope="col">Elimina</th>
                        </tr>
                    </thead>
                    <tbody className="rounded-row">
                        {esami?.map((pa) => <RowCustom colums={["id", "dataEsame", "tipoEsame","action"]} elementUpdate={"dettaglio"} update={(el) => props.dettaglioTestEsame(pa)}  reference={'idPaziente'} elementDelete={'action'} delete={(el) => showModalDelete(pa.id)} item={pa}  customIconUpdate={<FontAwesomeIcon icon={faMagnifyingGlass} className='c-icon-w'/>}/>)}
                    </tbody>
                </table>
                <Pagination
                    elementPerPage={elementsPerPage}
                    totalElements={esami?.length}
                    paginate={paginate}
                    currentPage={currentPage}
                    prevPage={prevPage}
                    nextPage={nextPage}
                />
            </div>
            <Modal show={modalNewEsame} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{'Nuovo Esame'}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <Row>
                        <Form.Group className="mb-3" >
                            <Form.Label>Tipo di esame:</Form.Label>
                            <Form.Select
                                className={''}
                                name="idTipoEsame"
                                onChange={handleInputTipoVisita}
                            >
                                <option value="0">Seleziona tipo di esame</option>
                                <option value="1">Analisi delle urine</option>
                                <option value="2">Analisi del sangue</option>
                            </Form.Select>
                        </Form.Group>
                    </Row>
                    
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="primary"  onClick={() => handleSubmit()}>
                        {'Salva'}
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal show={showmodalDelete} onHide={handleCloseDelete}>
                <Modal.Header closeButton>
                    <Modal.Title>{'Elimina Esame'}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{'Vuoi eliminare esame'}</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseDelete}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={() => eliminaesame(idDelete)}>
                        {'Elimina'}
                    </Button>
                </Modal.Footer>
            </Modal>
            <NotificationContainer></NotificationContainer>
        </div>
        
    );
}

export default Esame;
