import { Form, Button } from 'react-bootstrap';
import { useState, useEffect } from 'react';
import Pagination from '../CustomComponent/Pagination';
import RowCustom from '../CustomComponent/CustomRow';
import Modal from 'react-bootstrap/Modal';
import {PATH} from '../../helpers/Constants'
import { useNavigate } from 'react-router-dom';
import { web } from '../../helpers/api';
import { message, entitiesLabels, ENDPOINT } from '../../helpers/Constants';
import { NotificationContainer, NotificationManager } from 'react-notifications';
function ListaOperatori() {
  const navigate = useNavigate();
  const [elements, setOperatori] = useState([
  ]);

  // const [loading,] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [elementsPerPage] = useState(10);
  const [search, setSearch] = useState('');
  const [filteredElements, setFilteredElements] = useState([]);
  const [isFilter, setIsFilter] = useState(false);
  const [showModal, setShowModale] = useState(false);
  const [dropOffOperatore, setDropOffOperatore] = useState({
    idOperatore: 0,
    isAttivo: 0,
  });

  // Get current patient
  const indexOfLastElement = currentPage * elementsPerPage;
  const indexOfFirstElement = indexOfLastElement - elementsPerPage;
  let currentElements = isFilter ? filteredElements?.slice(indexOfFirstElement, indexOfLastElement) : elements?.slice(indexOfFirstElement, indexOfLastElement);

  useEffect(() => { 
    fetchOperatori();
}, []);
const fetchOperatori= async () => {
  await web.post(ENDPOINT.ELENCOOPERATORI)
      .then((response) => {
          if (response.status === 200) {
            setOperatori(response.data.dati);
          }
      }).catch((error) => {

      });
};
  // Change page
  const paginate = pageNumber => setCurrentPage(pageNumber);
  const handleClose = () => setShowModale(false);
  const showModalActivePatient = (idUser) => {
    var element = elements.find((x) => x.idOperatore === idUser);
    const idOperatore = 'idOperatore';
    const isAttivo = 'isAttivo';
    setDropOffOperatore({ ...dropOffOperatore, [isAttivo]: element.isAttivo , [idOperatore]: element.idOperatore });
    setShowModale(true)
  }
  const nextPage = (pageNumber) => {
    setCurrentPage(pageNumber + 1)
  }
  const prevPage = (pageNumber) => {
    setCurrentPage(pageNumber - 1)
  }
  const handleChange = (el) => {
    const inputValue = el.target.value.toLowerCase();
    setSearch({
      search:
        inputValue
    });
    if (inputValue) {
      if (search && search?.search.length >= 1) {
        setIsFilter(true);
        let filteredData = elements.filter(x => String(x.nome).toLowerCase().includes(inputValue)
        || String(x.cognome).toLowerCase().includes(inputValue)
        || String(x.idOperatore).toLowerCase().includes(inputValue)
        || String(x.telefono).toLowerCase().includes(inputValue)
        || String(x.eMail).toLowerCase().includes(inputValue));
        if (filteredData.length > 0) {
          setFilteredElements(filteredData);

        } else {
          setFilteredElements(filteredData);
        }
        setCurrentPage(1)
      } else {
        setFilteredElements(elements);
      }
    } else {
      setFilteredElements(elements);
    }
  };
  const disabilitaMedico = (e) => {
    web.post(ENDPOINT.DISABILITAOPERATORE, e)
      .then(async (response) => {
        if (response.status === 200 && response.data.statoEsito === 0) {
          NotificationManager.success(message.OPERATORE + message.SuccessUpdate, entitiesLabels.SUCCESS, 3000);
          fetchOperatori();
          setShowModale(false)
        } else {
          NotificationManager.error(response.data.descrizioneEsito, entitiesLabels.ERROR, 3000);
        }
      }).catch((error) => {
        NotificationManager.error(message.ErrorServer, entitiesLabels.ERROR, 3000);

      })
      .finally(() => {
        fetchOperatori();
      });
  };
  function toAddOperatore(){
   
    navigate(PATH.NUOVOOPERATORE, { replace: true });
  }
  return (<>
    <div className="container" style={{ maxWidth: '600px', margin: '0 auto' }}>
      <h2 className="text-center">Lista Operatori</h2>
    </div>
    <Form.Group className="d-flex mb-3">
      <Form.Control className="me-3" onChange={handleChange} onPaste={handleChange} name="search" alt="medicoDTO" placeholder="Ricerca Operatore"/>
      <Button variant="primary" onClick={() => toAddOperatore() }>
        Aggiungi Operatore
      </Button>
    </Form.Group>
    <>
      <div className="table-wrapper">
        <table className="table custom">
          <thead>
            <tr>
              <th scope="col">Id Operatore</th>
              <th scope="col">Nome</th>
              <th scope="col">Cognome</th>
              <th scope="col">Telefono</th>
              <th scope="col">Email</th>
              <th scope="col">Operatore Attivo</th>
              <th scope="col">Attiva e disattiva</th>
            </tr>
          </thead>
          <tbody className="rounded-row">
            {
              currentElements?.map((pa) => <RowCustom colums={["idOperatore", "nome","cognome", "telefono", "eMail", "isAttivo", "actions"]} link={'idOperatore'} elementUpdate={"actions"} reference={'idOperatore'} controller={'profilooperatore'} update={(el) => showModalActivePatient(el)}  item={pa} />)
            }
          </tbody>
        </table>
      </div>

    </>
    <Pagination
      elementPerPage={elementsPerPage}
      totalElements={isFilter ? filteredElements?.length : elements?.length}
      paginate={paginate}
      currentPage={currentPage}
      prevPage={prevPage}
      nextPage={nextPage}
    />
    <Modal show={showModal} onHide={handleClose}>
    <Modal.Header closeButton>
        <Modal.Title>{dropOffOperatore.isAttivo ? 'Disattiva Operatore' : 'Attiva Operatore'}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{dropOffOperatore.isAttivo ? 'Vuoi disattivare l\'operatore ?' : 'Vuoi riattivare  l\'operatore?'}</Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button variant="primary" onClick={() => disabilitaMedico(dropOffOperatore.idOperatore)}>
          {dropOffOperatore.isAttivo ? 'Disattiva' : 'Attiva'}
        </Button>
      </Modal.Footer>
    </Modal>
    <NotificationContainer></NotificationContainer>
  </>
  );
}

export default ListaOperatori; 