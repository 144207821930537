import { Form, Button, Row, Col } from 'react-bootstrap';
import { useState, useEffect } from 'react';
import { dasboard } from '../../helpers/api';
import moment from 'moment';
import { ResponsivePie } from '@nivo/pie';
import { ResponsiveBar } from '@nivo/bar'
import { ENDPOINT } from '../../helpers/Constants';
function Daschboard() {
    const [nprofilled, setNprofilled] = useState([]);
    const [activeTotalDropOff, setActiveTotalDropOff] = useState([]);
    const [hospitalInvolved, setHospitalInvolved] = useState([]);
    const [feedback, setfeedback] = useState([]);
    const [formData, setFormData] = useState({

        dataInizio: moment().startOf('year').format("YYYY-MM-DD"),
        dataFine: moment().format("YYYY-MM-DD"),
    });

    useEffect(() => {
        fetchDascboard();
    }, [formData]);

    const fetchDascboard = async () => {
        await dasboard.post(ENDPOINT.GETFILLED, formData)
            .then((response) => {
                if (response.status === 200) {
                    setNprofilled(response.data.dati);
                }
            }).catch((error) => {

            });
        await dasboard.post(ENDPOINT.GETATTIVIDROPOFF, formData)
            .then((response) => {
                if (response.status === 200) {
                    setActiveTotalDropOff(response.data.dati);
                }
            }).catch((error) => {

            });
        await dasboard.post(ENDPOINT.GETHOSPITALSINVOLVED, formData)
            .then((response) => {
                if (response.status === 200) {
                    setHospitalInvolved(response.data.dati);
                }
            }).catch((error) => {

            });
        await dasboard.post(ENDPOINT.GETFEEDBACK, formData)
            .then((response) => {
                if (response.status === 200) {
                    setfeedback(response.data.dati);
                }
            }).catch((error) => {

            });
    };
    const handleDateChange = (date) => {
        const { name, value } = date.target;
        setFormData({ ...formData, [name]: value });
    }

    return (
        <div className="" style={{  }}>
            <h2 className="text-center">Dashboard</h2>

            <Form.Group className="d-flex mb-3">
                <Row>
                    <Col>
                        <div className="col-12">
                            <span id="label-inizio">Data iniziale </span>
                            <div className="input-group mb-3">
                                <input
                                    type="date"
                                    defaultValue={moment().startOf('year').format("YYYY-MM-DD")}
                                    className="form-control form-control-sm"
                                    id="dataInizio"
                                    name="dataInizio"
                                    aria-describedby="label-inizio"
                                    onChange={handleDateChange}
                                    value={
                                        formData.dataInizio
                                            ? moment(formData.dataInizio, ["DD/MM/YYYY", "YYYY-MM-DD"]).format("YYYY-MM-DD")
                                            : moment().startOf('year').format("YYYY-MM-DD")
                                    }
                                />
                            </div>
                        </div>
                    </Col>
                    <Col>
                        <div className="col-12">
                            <span id="label-inizio">Data finale: </span>
                            <div className="input-group mb-3">
                                <input
                                    type="date"
                                    defaultValue={moment().format("YYYY-MM-DD")}
                                    className="form-control form-control-sm"
                                    id="dataFine"
                                    name="dataFine"
                                    aria-describedby="label-inizio"
                                    onChange={handleDateChange}
                                    value={
                                        formData.dataFine
                                            ? moment(formData.dataFine, ["DD/MM/YYYY", "YYYY-MM-DD"]).format("YYYY-MM-DD")
                                            : moment().format("YYYY-MM-DD")
                                    }
                                />
                            </div>
                        </div>
                    </Col>
                </Row>
            </Form.Group>
            <Row>
                <Col xs={6}>
                    <div style={{ height: 400,  width:450  }}>
                        <h6 className="text-center mt-4" >Pazienti Attivi - Non Attivi</h6> 
                        <ResponsivePie
                            data={activeTotalDropOff}
                            margin={{ top: 0, right: 80, bottom: 80, left: 80 }}
                            activeOuterRadiusOffset={8}
                            borderWidth={1}
                            borderColor={{
                                from: 'color',
                                modifiers: [
                                    [
                                        'darker',
                                        0.2
                                    ]
                                ]
                            }}
                            arcLinkLabelsSkipAngle={10}
                            arcLinkLabelsTextColor="#333333"
                            arcLinkLabelsThickness={2}
                            arcLinkLabelsColor={{ from: 'color' }}
                            arcLabelsSkipAngle={10}
                            arcLabelsTextColor={{
                                from: 'color',
                                modifiers: [
                                    [
                                        'darker',
                                        2
                                    ]
                                ]
                            }}
                            enableArcLinkLabels={false}
                            legends={[
                                {
                                    anchor: 'bottom',
                                    direction: 'row',
                                    justify: false,
                                    translateX: 0,
                                    translateY: 56,
                                    itemsSpacing: 30,
                                    itemWidth: 100,
                                    itemHeight: 18,
                                    itemTextColor: '#999',
                                    itemDirection: 'left-to-right',
                                    itemOpacity: 1,
                                    symbolSize: 18,
                                    symbolShape: 'circle',
                                    effects: [
                                        {
                                            on: 'hover',
                                            style: {
                                                itemTextColor: '#000'
                                            }
                                        }
                                    ]
                                }
                            ]}
                        />
                    </div>
                </Col>
                <Col xs={6}>
                    <div style={{ height: 400 }}>
                    <h6 className="text-center mt-4" >Strutture coinvolte</h6> 
                    {
                        <ResponsivePie
                            data={hospitalInvolved}
                            margin={{ top: 0, right: 80, bottom: 80, left: 80 }}
                            activeOuterRadiusOffset={8}
                            borderWidth={1}
                            borderColor={{
                                from: 'color',
                                modifiers: [
                                    [
                                        'darker',
                                        0.2
                                    ]
                                ]
                            }}
                            arcLinkLabelsSkipAngle={10}
                            arcLinkLabelsTextColor="#333333"
                            arcLinkLabelsThickness={2}
                            arcLinkLabelsColor={{ from: 'color' }}
                            arcLabelsSkipAngle={10}
                            arcLabelsTextColor={{
                                from: 'color',
                                modifiers: [
                                    [
                                        'darker',
                                        2
                                    ]
                                ]
                            }}
                            enableArcLinkLabels={false}
                            legends={[
                                {
                                    anchor: 'bottom',
                                    direction: 'row',
                                    justify: false,
                                    translateX: 0,
                                    translateY: 56,
                                    itemsSpacing: 0,
                                    itemWidth: 100,
                                    itemHeight: 18,
                                    itemTextColor: '#999',
                                    itemDirection: 'left-to-right',
                                    itemOpacity: 1,
                                    symbolSize: 18,
                                    symbolShape: 'circle',
                                    effects: [
                                        {
                                            on: 'hover',
                                            style: {
                                                itemTextColor: '#000'
                                            }
                                        }
                                    ]
                                }
                            ]}
                        /> 
                        }
                    </div>
                </Col>
            </Row>
            <Row>
                <Col xs={6}>
                    <div style={{ height: 400 , width:450 }}>
                        <h6 className="text-center mt-4" >Stato compilazione Questionari</h6> 
                        <ResponsivePie
                            data={nprofilled}
                            margin={{ top: 0, right: 80, bottom: 80, left: 80 }}
                            activeOuterRadiusOffset={8}
                            borderWidth={1}
                            borderColor={{
                                from: 'color',
                                modifiers: [
                                    [
                                        'darker',
                                        0.2
                                    ]
                                ]
                            }}
                            arcLinkLabelsSkipAngle={10}
                            arcLinkLabelsTextColor="#333333"
                            arcLinkLabelsThickness={2}
                            arcLinkLabelsColor={{ from: 'color' }}
                            arcLabelsSkipAngle={10}
                            arcLabelsTextColor={{
                                from: 'color',
                                modifiers: [
                                    [
                                        'darker',
                                        2
                                    ]
                                ]
                            }}
                            enableArcLinkLabels={false}
                            legends={[
                                {
                                    anchor: 'bottom',
                                    direction: 'row',
                                    justify: false,
                                    translateX: 0,
                                    translateY: 56,
                                    itemsSpacing: 30,
                                    itemWidth: 100,
                                    itemHeight: 18,
                                    itemTextColor: '#999',
                                    itemDirection: 'left-to-right',
                                    itemOpacity: 1,
                                    symbolSize: 18,
                                    symbolShape: 'circle',
                                    effects: [
                                        {
                                            on: 'hover',
                                            style: {
                                                itemTextColor: '#000'
                                            }
                                        }
                                    ]
                                }
                            ]}
                        />
                    </div>
                </Col>
                <Col xs={6}>
                <h6 className="text-center mt-4" >Feedback ricevuti</h6> 
                <div style={{ height: 400, width:450 }}>
                        <ResponsivePie
                            data={feedback}
                            margin={{ top: 0, right: 80, bottom: 80, left: 80 }}
                            activeOuterRadiusOffset={8}
                            borderWidth={1}
                            borderColor={{
                                from: 'color',
                                modifiers: [
                                    [
                                        'darker',
                                        0.2
                                    ]
                                ]
                            }}
                            arcLinkLabelsSkipAngle={10}
                            arcLinkLabelsTextColor="#333333"
                            arcLinkLabelsThickness={2}
                            arcLinkLabelsColor={{ from: 'color' }}
                            arcLabelsSkipAngle={10}
                            arcLabelsTextColor={{
                                from: 'color',
                                modifiers: [
                                    [
                                        'darker',
                                        2
                                    ]
                                ]
                            }}
                            enableArcLinkLabels={false}
                            legends={[
                                {
                                    anchor: 'bottom',
                                    direction: 'row',
                                    justify: false,
                                    translateX: 0,
                                    translateY: 56,
                                    itemsSpacing: 0,
                                    itemWidth:90,
                                    itemHeight: 18,
                                    itemTextColor: '#999',
                                    itemDirection: 'left-to-right',
                                    itemOpacity: 1,
                                    symbolSize: 18,
                                    symbolShape: 'circle',
                                    effects: [
                                        {
                                            on: 'hover',
                                            style: {
                                                itemTextColor: '#000'
                                            }
                                        }
                                    ]
                                }
                            ]}
                        />
                    </div>
                </Col>
            </Row>
        </div>
    );
}

export default Daschboard; 