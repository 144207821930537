import React, { useState,useEffect } from 'react';
import { Tabs, Tab } from 'react-bootstrap';
import RegistrazionePaziente from '../Registrazione/RegistrazionePaziente';
import AderenzaTerapia from './AderenzaTerapia';
import Visite from './Visite';
import Esame from './Esame';
import Analisi from './Analisi';
import TestEsame from './TestEsame';
import QuestionariUser from './QuestionariUser';
import Feedback from './Feedback';
import { isAdmin, isOperatore } from '../../utils';
import Allegati from './Allegati';
import { web } from '../../helpers/api';
import {  ENDPOINT } from '../../helpers/Constants';
function TabsPaziente() {
    const [formData, setFormData] = useState({});
    const [key, setKey] = useState('profilopaziente');
    const [showVisita, setShowVisita] = useState(true);
    const [showEsame, setShowEsame] = useState(true);
    const [showAllegati, setShowAllegati] = useState(true);
    const [visita, setVisita] = useState({});
    const [esame, setEsame] = useState({});
    const [analisi, setAnalisi] = useState({});
    const dettaglioEsame = (el) => {
        setVisita(el)
        setShowVisita(!showVisita)
    }
    const dettaglioTestEsame = (el) => {
        setEsame(el)
        setShowEsame(!showEsame)
    }
    const dettaglioAllegati = (el) => {
        setAnalisi(el)
        setShowAllegati(!showAllegati)
    }
    useEffect(() => {
        fetchProfile();
    }, [key]);
    const fetchProfile = async () => {
        let id = window.location.pathname.split('/').pop();
        id = parseInt(id);
        if (id) {
            await web.post(ENDPOINT.DETTAGLIOPAZIENTE, id)
                .then((response) => {
                    if (response.status === 200 && response.data.dati != null) {
                        setFormData(response.data.dati);
                    }
                }).catch((error) => {

                });
        }
    };
    return (
        <Tabs
            id="charts-tabs"
            activeKey={key}
            onSelect={(k) => setKey(k)}
            className="nav secondary-menu mb-4"
        >
            <Tab eventKey="profilopaziente" title={"Paziente "+formData.nome+ " "+formData.cognome}>
                <RegistrazionePaziente key={key}/>
            </Tab>
            {(isAdmin() || isOperatore()) && (
                <Tab eventKey="aderenza" title="Aderenza Terapia">
                    <AderenzaTerapia key={key} />
                </Tab>
            )}
            {(isAdmin() || isOperatore()) && (
                <Tab eventKey="feeddback" title="Gradimento">
                 <Feedback key={key}></Feedback>
                </Tab> 
            )}
            {(isAdmin() || isOperatore()) && (
                <Tab eventKey="reminder" title="Visite">
                {showVisita ?<Visite dettaglioEsame={(el) => dettaglioEsame(el)}  key={key}/> : showEsame? <Esame key={key}  dettaglioTestEsame={(el) => dettaglioTestEsame(el)}  mostralistaVisite={() => setShowVisita(true)}  dettaglioVisita={visita}></Esame>:<TestEsame dettaglioEsame={esame} mostralistaEsami={() => setShowEsame(true)} key={key}></TestEsame> }
                </Tab> 
            )}
            <Tab eventKey="visita" title="Analisi">
            {showAllegati ?<Analisi dettaglioAllegati={(el) => dettaglioAllegati(el)}  key={key}></Analisi>: <Allegati key={key} dettaglioAllegato={analisi}  mostralistaAnalisi={() => setShowAllegati(true)}></Allegati>}
            </Tab>
            <Tab eventKey="questionari" title="Questionari">
                <QuestionariUser key={key}></QuestionariUser>
            </Tab>
        </Tabs>
    );
}

export default TabsPaziente;